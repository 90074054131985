.waste-flip-button-image-1 {
  border-radius: 10px;
  height: 350px;
  width: 250px;
  background-image: url("../../../assets/new_conserve_energy.png");
  background-size: cover;
}

.waste-flip-button-image-1:hover {
  background-image: none;
  background-color: #313893;
}

.waste-flip-button-image-2 {
  border-radius: 10px;
  height: 350px;
  width: 250px;
  background-image: url("../../../assets/new_conserve_water_02.webp");
  background-size: cover;
}

.waste-flip-button-image-2:hover {
  /* background-image: url("../../../assets/happy-people-11934050.webp"); */
  background-image: none;
  background-color: #19322a;
}

.waste-flip-button-image-3 {
  border-radius: 10px;
  height: 350px;
  width: 250px;
  background-image: url("../../../assets/new_conserve_chemical_02.webp");
  background-size: cover;
}

.waste-flip-button-image-3:hover {
  /* background-image: url("../../../assets/pexels-anjana-c-674010.jpg"); */
  background-image: none;
  background-color: #3c90bb;
}

.waste-flip-button-image-4 {
  border-radius: 10px;
  height: 350px;
  width: 250px;
  /* background-image: url("../../../assets/new_conserve_waste.png"); */
  background-color: #03230e;
  background-size: cover;
}

.waste-flip-button-image-4:hover {
  /* background-image: url("../../../assets/11556540.webp"); */
  background-image: none;
  background-color: #03230e6b;
}
