@font-face {
  font-family: "GT-America-st-tr-bold";
  src: url("../../../../../fonts/GT-America-Standard-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}
.career-mobile {
  display: none;
}

.main-topic-chart-p {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: rgb(49, 56, 147);
  font-family: "GT America Trial Md";
}

.career-chart-4-two-column {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  padding-top: 20px;
  padding-right: 20px;
  border-left: 1.4px solid #313893;
  border-bottom: 1.4px solid #313893;
  font-family: "GT-America-st-tr-bold";
}

.career-chart-4-column-1 {
  width: 70%;
}

.career-chart-4-column-2 {
  width: 30%;
  position: relative;
}

@media screen and (max-width: 768px) {
  .career-chart-4-two-column {
    flex-direction: column;
    height: auto;
    width: 90%;
  }

  .career-chart-4-column-1 {
    width: 100%;
    text-align: center;
  }

  .career-chart-4-column-2 {
    width: 100%;
    text-align: center;
    display: none;
  }
}

.chart-topic-button {
  /* color: #00438f85; */
  color: #313893;
  font-size: 20px;
  text-decoration: none;
  font-weight: 700;
  text-align: start;
}

.chart-topic-button.selected {
  /* color: #00438f; */
  color: #000055;
}

.button-a-chart {
  bottom: 0;
  position: absolute;
}

@media (max-width: 768px) {
  .career-pc {
    display: none;
  }
}

@media (max-width: 769px) {
  .career-mobile {
    width: 100%;
    display: block;
  }

  .career-chart-04-mobile-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}