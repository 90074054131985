.marquree-part {
  font-size: 30px;
  color: #0f3b0f;
}

.font-icon {
  color: #73ddd1;
  margin-left: 30px;
  margin-right: 30px;
}

.marquee-content {
  display: inline-block;
  border-bottom: 1px solid #bdbdbd;
  padding-top: 10px;
  padding-bottom: 10px;
}

.marquee-content-2 {
  display: inline-block;
  border-bottom: 1px solid #bdbdbd;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 100px;
}

.marquee-content-1 {
  margin-top: 100px;
  display: inline-block;
  border-bottom: 1px solid #bdbdbd;
  border-top: 1px solid #bdbdbd;
  padding-top: 10px;
  padding-bottom: 10px;
}