@font-face {
  font-family: "GT America Trial Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Lt"),
    url("../../fonts/GT-America-Standard-Light-Trial.woff") format("woff");
}


.values-loader {
  position: relative;
  font-size: 46px;
  line-height: 36px;
  font-weight: 700;
  color: #313893;
  z-index: 99;
}

/* conserve circle */
.card-circle-conserve1 {
  color: #313893;
  font-size: 16px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3ce5b2;
  margin-left: 170px;
  margin-top: -60px;
  cursor: pointer;
}

.card-circle-conserve1 p {
  margin: 0;
  font-size: 13px;
}

.card-circle-conserve2 {
  color: #313893;
  font-size: 16px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3ce5b2;
  margin-left: 135px;
  margin-top: -60px;
  cursor: pointer;
}

.card-circle-conserve2 p {
  margin: 0;
  font-size: 13px;
}

.card-circle-conserve3 {
  color: #313893;
  font-size: 16px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3ce5b2;
  margin-left: 115px;
  margin-top: -60px;
  cursor: pointer;
}

.card-circle-conserve3 p {
  margin: 0;
  font-size: 13px;
}

/* empower circle */

.empower-circle1 {
  color: #313893;
  font-size: 16px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3ce5b2;
  margin-left: 135px;
  margin-top: -60px;
  cursor: pointer;
}

.empower-circle1 p {
  margin: 0;
  font-size: 13px;
}

.empower-circle2 {
  color: #313893;
  font-size: 16px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3ce5b2;
  margin-left: 160px;
  margin-top: -60px;
  cursor: pointer;
}

.empower-circle2 p {
  margin: 0;
  font-size: 13px;
}

.empower-circle3 {
  color: #313893;
  font-size: 16px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3ce5b2;
  margin-left: 160px;
  margin-top: -60px;
  cursor: pointer;
}

.empower-circle3 p {
  margin: 0;
  font-size: 13px;
}

.card-circle {
  color: #313893;
  font-size: 16px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3ce5b2;
  margin-left: 215px;
  margin-top: -60px;
  cursor: pointer;
}

.card-circle p {
  margin: 0;
  font-size: 13px;
}

.values-para {
  width: 70%;
  color: #313893;
  font-size: 22px;
  font-weight: 500;
  line-height: 37.76px;
  margin-top: 30px;
  margin-bottom: 40px;
  z-index: 2;
  position: relative;
}

.horizontal-line {
  height: 0.9px;
  background-color: #313893;
  width: 70%;
  z-index: 2;
  position: relative;
}

.r-part {
  margin-left: 35%;
}

@media screen and (max-width: 768px) {
  .horizontal-line {
    width: 93%;
  }

  .values-para {
    padding-left: 170px;
  }

  .values-loader {
    padding-left: 170px;
  }

  .r-part {
    margin-left: 0%;
  }
}

@media screen and (max-width: 600px) {
  .horizontal-line {
    width: 100%;
  }

  .values-para {
    width: 80%;
    padding-left: 65px;
  }

  .values-loader {
    padding-left: 65px;
  }
}

.hover-area-home-page{
  visibility: hidden;
  width: 50%;
  text-align: center;
  padding: 10px 5px;
  border-radius: 6px;
  background-color: #3ce5b2;
  position: absolute;
  left: 30%;
  top: -200%;
}

.tooltip-texts-home-page{
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16px;
  font-weight: 400;
  font-family: "GT America Trial Lt";
}

.hover-area-arrow-home-page{
  rotate: 180deg;
  position: absolute;
  left: -15px;
  top: 15%;
  color: #3ce5b2;
}

.hover-area-home-page-empower-01{
  left: 31%;
  top: -200%;
}

.hover-area-home-page-empower-02{
  left: 34%;
  top: -200%;
}

.hover-area-home-page-empower-03{
  left: 34%;
  top: -200%;
}

.hover-area-home-page-conserve-01{
  left: 35%;
  top: -200%;
}

.hover-area-home-page-conserve-02{
  left: 30%;
  top: -200%;
}

.hover-area-home-page-conserve-03{
  left: 27%;
  top: -200%;
}

.hover-area-home-page-support-01{
  left: 41%;
  top: -200%;
}

.hover-area-home-page-support-02{
  left: 41%;
  top: -200%;
}

.hover-area-home-page-support-03{
  left: 41%;
  top: -200%;
}

.empower-circle1:hover + .hover-area-home-page-empower-01{
  visibility: visible;
}

.empower-circle2:hover + .hover-area-home-page-empower-02{
  visibility: visible;
}

.empower-circle3:hover + .hover-area-home-page-empower-03{
  visibility: visible;
}

.card-circle-conserve1:hover + .hover-area-home-page-conserve-01{
  visibility: visible;
}

.card-circle-conserve2:hover + .hover-area-home-page-conserve-02{
  visibility: visible;
}

.card-circle-conserve3:hover + .hover-area-home-page-conserve-03{
  visibility: visible;
}

.card-circle-support-01:hover + .hover-area-home-page-support-01{
  visibility: visible;
}

.card-circle-support-02:hover + .hover-area-home-page-support-02{
  visibility: visible;
}

.card-circle-support-03:hover + .hover-area-home-page-support-03{
  visibility: visible;
}


@media (max-width:1280px) {
  .hover-area-home-page-conserve-01{
    left: 37%;
  }
  
  .hover-area-home-page-conserve-02{
    left: 32%;
  }
  
  .hover-area-home-page-conserve-03{
    left: 29%;
  }

  .hover-area-home-page-empower-01{
    left: 33%;
  }
  
  .hover-area-home-page-empower-02{
    left: 37%;
  }
  
  .hover-area-home-page-empower-03{
    left: 37%;
  }

  .hover-area-home-page-support-01{
    left: 44%;
  }
  
  .hover-area-home-page-support-02{
    left: 44%;
  }
  
  .hover-area-home-page-support-03{
    left: 44%;
  }
}

@media (max-width:1200px) {
  .hover-area-home-page-conserve-01{
    left: 40%;
  }
  
  .hover-area-home-page-conserve-02{
    left: 35%;
  }
  
  .hover-area-home-page-conserve-03{
    left: 32%;
  }

  .hover-area-home-page-empower-01{
    left: 35%;
  }
  
  .hover-area-home-page-empower-02{
    left: 39%;
  }
  
  .hover-area-home-page-empower-03{
    left: 39%;
  }

  .hover-area-home-page-support-01{
    left: 47%;
  }
  
  .hover-area-home-page-support-02{
    left: 47%;
  }
  
  .hover-area-home-page-support-03{
    left: 47%;
  }
}

@media (max-width:1190px) {
  .hover-area-home-page-conserve-01{
    left: 22%;
  }
  
  .hover-area-home-page-conserve-02{
    left: 18%;
  }
  
  .hover-area-home-page-conserve-03{
    left: 16%;
  }

  .hover-area-home-page-empower-01{
    left: 18%;
  }
  
  .hover-area-home-page-empower-02{
    left: 20%;
  }
  
  .hover-area-home-page-empower-03{
    left: 20%;
  }

  .hover-area-home-page-support-01{
    left: 25%;
  }
  
  .hover-area-home-page-support-02{
    left: 25%;
  }
  
  .hover-area-home-page-support-03{
    left: 25%;
  }
}

@media (max-width:1040px) {
  .hover-area-home-page-conserve-01{
    left: 25%;
  }
  
  .hover-area-home-page-conserve-02{
    left: 21%;
  }
  
  .hover-area-home-page-conserve-03{
    left: 19%;
  }

  .hover-area-home-page-empower-01{
    left: 21%;
  }
  
  .hover-area-home-page-empower-02{
    left: 23%;
  }
  
  .hover-area-home-page-empower-03{
    left: 23%;
  }

  .hover-area-home-page-support-01{
    left: 29%;
  }
  
  .hover-area-home-page-support-02{
    left: 29%;
  }
  
  .hover-area-home-page-support-03{
    left: 29%;
  }
}

@media (max-width:940px) {

  .hover-area-arrow-home-page{
    display: none;
  }
  .hover-area-home-page-conserve-01{
    left: 0%;
    top: 170%;
  }
  
  .hover-area-home-page-conserve-02{
    left: 0%;
    top: 170%;
  }
  
  .hover-area-home-page-conserve-03{
    left: 0%;
    top: 170%;
  }

  .hover-area-home-page-empower-01{
    left: 0%;
    top: 170%;
  }
  
  .hover-area-home-page-empower-02{
    left: 0%;
    top: 170%;
  }
  
  .hover-area-home-page-empower-03{
    left: 0%;
    top: 170%;
  }

  .hover-area-home-page-support-01{
    left: 0%;
    top: 170%;
  }
  
  .hover-area-home-page-support-02{
    left: 0%;
    top: 170%;
  }
  
  .hover-area-home-page-support-03{
    left: 0%;
    top: 170%;
  }
}

@media (max-width:800px) {
  .hover-area-home-page-conserve-01{
    left: 30%;
  }
  
  .hover-area-home-page-conserve-02{
    left: 30%;
  }
  
  .hover-area-home-page-conserve-03{
    left: 30%;
  }

  .hover-area-home-page-empower-01{
    left: 30%;
  }
  
  .hover-area-home-page-empower-02{
    left: 30%;
  }
  
  .hover-area-home-page-empower-03{
    left: 30%;
  }

  .hover-area-home-page-support-01{
    left: 30%;
  }
  
  .hover-area-home-page-support-02{
    left: 30%;
  }
  
  .hover-area-home-page-support-03{
    left: 30%;
  }
}