@font-face {
  font-family: "GT-America-st-tr-bold";
  src: url("../../../../fonts/GT-America-Standard-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT-America-2";
  src: url("../../../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT America Trial Cm Bd";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bd"),
    url("../../../../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}

.conserve-chart-2-paragraph-two-column {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.conserve-chart-2-paragraph-column-2-new{
  width: 100%;
  font-family: "GT-America-st-tr-bold";
  /* font-family: "GT America Trial Cm Bd"; */
}

.conserve-chartHeading{
  color: #313893;
  /* color: #000055; */
  font-size: 16px;
  font-weight: 900;
  font-family: "GT America Trial Md";
  margin-bottom: 70px;
}

.conserve-chart-02-card-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.conserve-chart-02-card {
  flex: 1 1 auto;
  min-width: 180px;
  overflow: hidden;
  margin: 2.5px;
  margin-bottom: 20px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  justify-items: center;
  text-align: center;
}

.conserve-chart-02-number {
  font-size: 18px;
  font-weight: 700;
}

.conserve-chart-02-number.number-content-01{
  /* color: #b80101; */
  color: #000055;
}

.conserve-chart-02-number.number-content-02{
  /* color: rgb(17, 107, 79); */
  color: #000055;
}

.conserve-chart-02-letter{
  font-weight: 700;
  letter-spacing: 3%;
  margin-top: 15px;
}

.conserve-chart-02-letter.letter-content-01 h4{
  /* color: #b80101; */
  color: #000055;
  font-size: 18px;
}

.conserve-chart-02-letter.letter-content-02 h4{
  /* color: rgb(17, 107, 79); */
  color: #000055;
  font-size: 18px;
}