@font-face {
  font-family: "GT-America-2";
  src: url("../../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT America Trial Cm Bd";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bd"),
    url("../../../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}

.community-animation-para {
  background-color: #1f6d55;
  color: #ffffff;
  width: 100%;
  padding: 80px 60px;
  font-size: 17px;
  height: 500px;
}

.community-animation-para-center {
  text-align: left;
  width: 100%;
}

.community-animation-para-center h1{
  font-family: "GT America Trial Cm Bd";
}
.community-animation-para-center p {
  margin-bottom: 20px;
  font-size: 20px;
  /* font-family: "Arial Narrow", Arial, sans-serif;   */
  font-family: "GT America Trial Cn Lt";
}

.community-animation-para-center-yello-p {
  color: #f0ec73;
  padding-left: 50px;
}


@media only screen and (max-width: 200px) {
  .community-animation-para {
    height: auto;
  }

  .community-animation-para {
    padding: 80px 10px;
  }
  
}

@media only screen and (min-width:200px) {
  .community-animation-para {
    height: 900px;
  }

  .community-animation-para {
    padding: 80px 30px;
  }

}

@media only screen and (min-width:250px) {
  .community-animation-para {
    height: 800px;
  }

  .community-animation-para {
    padding: 80px 30px;
  }

}

@media only screen and (min-width: 350px) {
  .community-animation-para {
    height: 700px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .community-animation-para {
    height: 450px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .community-animation-para {
    height: 450px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .community-animation-para {
    height: 450px;
    padding: 80px 60px;
  }
}

.community-animation-para {
  display: flex;
  align-items: center;
}

/* section-2 end */