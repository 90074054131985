@font-face {
  font-family: "GT-America-2";
  src: url("../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT America Trial Cm Bd";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bd"),
    url("../../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}
/* font-family: 'GT-America-2', sans-serif; */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .blueArea {
    height: auto;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .blueArea {
    height: 800px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .blueArea {
    height: 450px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .blueArea {
    height: 450px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .blueArea {
    height: 450px;
  }
}

.blueArea {
  display: flex;
  align-items: center;
}

.conservepage-para {
  padding-top: 100px;
  padding: 60px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  position: relative;
}

.conservepage-para h1 {
  font-family: "GT America Trial Cm Bd";
}

/* .conservepage-para p {
  font-family: "Arial Narrow", Arial, sans-serif;
  font-size: 20px;
} */

.parag {
  width: 100%;
  font-family: "GT America Trial Cn Lt";
  font-size: 20px;
}
