/* 
.card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  justify-content: center;
}

.card-link {
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.card-front,
.card-back {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.card-front {
  background-color: #41669d;
}

.card-back {
  background-color: #175852;
} 
*/

.energy-flip-button-image-1 {
  border-radius: 10px;
  height: 350px;
  width: 250px;
  /* background-image: url("../../../assets/new_conserve_energy.png"); */
  background-color: #313893;
  background-size: cover;
}

.energy-flip-button-image-1:hover {
  /* background-image: url("../../../assets/dog.png"); */
  background-color: #31379343;
}

.energy-flip-button-image-2 {
  border-radius: 10px;
  height: 350px;
  width: 250px;
  background-image: url("../../../assets/new_conserve_water_02.webp");
  background-size: cover;
}

.energy-flip-button-image-2:hover {
  /* background-image: url("../../../assets/happy-people-11934050.webp"); */
  background-image: none;
  background-color: #19322a;
}

.energy-flip-button-image-3 {
  border-radius: 10px;
  height: 350px;
  width: 250px;
  background-image: url("../../../assets/new_conserve_chemical_02.webp");
  background-size: cover;
}

.energy-flip-button-image-3:hover {
  /* background-image: url("../../../assets/pexels-anjana-c-674010.jpg"); */
  background-image: none;
  background-color: #3c90bb;
}

.energy-flip-button-image-4 {
  border-radius: 10px;
  height: 350px;
  width: 250px;
  background-image: url("../../../assets/new_conserve_waste_02.webp");
  background-size: cover;
}

.energy-flip-button-image-4:hover {
  /* background-image: url("../../../assets/11556540.webp"); */
  background-image: none;
  background-color: #03230e;
}
