.gender-container-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 71px;
  position: relative; /* Add position relative */
}

.gender-number-loader {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #ffffff;
}

.gender-middle-topic {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  font-weight: 700;
  color: #ffffff;
}

.gender-image {
  position: relative; /* Set position relative */
  max-width: 100%;
  height: 100%;
  z-index: 1; /* Set z-index to bring the image above the water */
  object-fit: cover; /* Ensure the image covers the entire container */
}

.gender1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #f5c1c1; */
  /* background-color: #00438f85; */
  background-color: #313893;
}

.gender-water1 {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  height: 0; /* Initialize height to 0 */
  width: 100%;
  /* background-color: #00438f; */
  background-color: #000055;
  transition: height 0.5s ease; /* Smooth transition for height change */
}

.gender3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #b8010185; */
  background-color: #313893;
}

.gender-water3 {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  height: 0; /* Initialize height to 0 */
  width: 100%;
  /* background-color: #b80101; */
  background-color: #000055;
  transition: height 0.5s ease; /* Smooth transition for height change */
}
