@font-face {
  font-family: "GT-America";
  src: url("../../fonts/GT-America-Compressed-Black-Trial.woff")
    format("truetype");
  font-style: normal;
}
/* font-family: 'GT-America', sans-serif; */

.icon-container {
  display: grid;
  grid-template-columns: repeat(4, 250px);
  grid-template-rows: auto;
  gap: 20px;
  justify-content: center;
}

.pillars-topic {
  font-size: 70px;
  font-weight: 700;
  line-height: 99.84px;
  font-family: "GT-America", sans-serif;
  color: #313893;
  margin-bottom: 50px;
}

.icon-column {
  padding: 0px;
  position: relative;
}

.topic-overlay {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  font-size: 36px;
  font-family: "GT-America", sans-serif;
}

.ban-gap {
  width: 100%;
}

@media (max-width: 768px) {
  .ban-gap {
    width: 80%;
  }
  .icon-container {
    margin-top: 20px;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-items: center;
  }

  .icon-column {
    grid-column: span 1;
    justify-self: center;
  }
}

@media (max-width: 600px) {
  .icon-container {
    margin-top: 20px;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}

.button-image-1 {
  height: 350px;
  width: 250px;
  background-image: url("../../assets/our_piller_img_conserve_02-V2.webp");
  background-size: cover;
  background-position: center;
}

.button-image-1:hover {
  background-image: url("../../assets/img1.webp");
}

.button-image-2 {
  height: 350px;
  width: 250px;
  background-image: url("../../assets/our_piller_img_empower_02.webp");
  background-size: cover;
  background-position: center;
}

.button-image-2:hover {
  background-image: url("../../assets/piller-2.webp");
}

.button-image-3 {
  height: 350px;
  width: 250px;
  background-image: url("../../assets/our_piller_img_support_02.webp");
  background-size: cover;
  background-position: center;
}

.button-image-3:hover {
  background-image: url("../../assets/piller-3.webp");
}

.button-image-4 {
  height: 350px;
  width: 250px;
  background-image: url("../../assets/our_piller_img_trust.webp");
  background-size: cover;
  background-position: center;
}

.button-image-4:hover {
  background-image: url("../../assets/piller-4.webp");
}

@media (max-width: 768px) {
  .button-image-1 {
    width: 300px;
  }
  .button-image-2 {
    width: 300px;
  }
  .button-image-3 {
    width: 300px;
  }
  .button-image-4 {
    width: 300px;
  }
}

@media (max-width: 600px) {
  .button-image-1 {
    width: 300px;
  }
  .button-image-2 {
    width: 300px;
  }
  .button-image-3 {
    width: 300px;
  }
  .button-image-4 {
    width: 300px;
  }
}
