.empower-wellbeing-chart-02-chart-section{
  width: 70%;
}

.empower-wellbeing-image-chart-02-container-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: 372px;
  position: relative;
  overflow: hidden;
}

.image-chart-02-image {
  max-width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
}

.image-chart-02 {
  position: absolute;
  top: 1%;
  left: 1%;
  width: 98%;
  height: 98%;
  background-color: #08313a85;
  overflow: hidden;
}

.image-chart-02-water-1 {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  color: #ffff;
  z-index: 0;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: #08313a;
  transition: height 0.5s ease;
  border-bottom: 1px solid #fff;
  padding-top: 15px;
}

.image-chart-02-water-2 {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  z-index: 0;
  top: 23%;
  left: 0;
  height: 0;
  width: 100%;
  background-color: #08313a;
  transition: height 0.5s ease;
  border-bottom: 1px solid #fff;
}

.image-chart-02-water-3 {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  z-index: 0;
  top: 41%;
  left: 0;
  height: 0;
  width: 100%;
  background-color: #08313a;
  transition: height 0.5s ease;
  border-bottom: 1px solid #fff;
}

.image-chart-02-water-4 {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  z-index: 0;
  top: 59%;
  left: 0;
  height: 0;
  width: 100%;
  background-color: #08313a;
  transition: height 0.5s ease;
  border-bottom: 1px solid #fff;
}

.image-chart-02-water-5 {
  position: absolute;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  display: flex;
  padding-top: 5px;
  z-index: 0;
  top: 78%;
  left: 0;
  height: 0;
  width: 100%;
  background-color: #08313a;
  transition: height 0.5s ease;
  padding-bottom: 15px;
}

@media (max-width:870px) {
  .empower-wellbeing-image-chart-02-container-2 {
    height: 400px;
    width: 425px;
  }
}

@media (max-width:640px) {
  .empower-wellbeing-image-chart-02-container-2 {
    height: 300px;
    width: 315px;
  }

  .image-chart-02-water-1 {
    font-size: 15px;
  }
  
  .image-chart-02-water-2 {
    font-size: 15px;
  }
  
  .image-chart-02-water-3 {
    font-size: 15px;
  }
  
  .image-chart-02-water-4 {
    font-size: 15px;
  }
  
  .image-chart-02-water-5 {
    font-size: 15px;
  }

}

@media (max-width:470px) {
  .empower-wellbeing-chart-02-chart-section{
    width: 100%;
  }

}

@media (max-width:355px) {
  .empower-wellbeing-image-chart-02-container-2 {
    height: 250px;
    width: 265px;
  }

  .image-chart-02-water-1 {
    font-size: 12px;
  }
  
  .image-chart-02-water-2 {
    font-size: 12px;
  }
  
  .image-chart-02-water-3 {
    font-size: 12px;
  }
  
  .image-chart-02-water-4 {
    font-size: 12px;
  }
  
  .image-chart-02-water-5 {
    font-size: 12px;
  }
}

@media (max-width:310px) {
  .empower-wellbeing-image-chart-02-container-2 {
    height: 200px;
    width: 215px;
  }
}