@font-face {
  font-family: "GT-America-st-tr-bold";
  src: url("../../../../../fonts/GT-America-Standard-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@media (max-width: 870px) {
  .aa-pc {
    display: none;
  }
}

@media (min-width: 870px) {
  .aa-mobile {
    display: none;
  }
}

.empower-chart-4-srilanka-paragraph-two-column.empower-equality-chart-02 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: "GT-America-st-tr-bold";
  padding: 0 15px;
}

.empower-chart-4-srilanka-paragraph-column-1-new{
  width: 5%;
  border-bottom: 1.4px solid #313893;
}

.empower-chart-4-srilanka-paragraph-column-2-new {
  width: 65%;
  border-left: 1.4px solid #313893;
  border-bottom: 1.4px solid #313893;
  border-right: none;
  padding-top: 5.5%;
}

.empower-chart-4-srilanka-paragraph-column-3-new.empower-equality-chart-02{
  text-align: start;
  width: 30%;
  border-bottom: 1.4px solid #313893;
  padding-bottom: 2.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empower-chart-4-srilanka-paragraph-column-4-new {
  width: 5%;
}

.empower-chart-4-srilanka-paragraph-column-5-new {
  width: 65%;
  border-left: 1.4px solid #313893;
  border-right:none;
}

.empower-chart-4-srilanka-paragraph-column-6 {
  width: 30%;
}

@media screen and (max-width: 870px) {
  .empower-chart-4-srilanka-paragraph-two-column.empower-equality-chart-02 {
    flex-direction: column;
    height: auto;
    width: 100%;
  }

  .empower-chart-4-srilanka-paragraph-column-2-new {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-right: none;
    border-left: 1.4px solid #313893;
  }

  .empower-chart-4-srilanka-paragraph-column-3-new.empower-equality-chart-02 {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-left: 1.4px solid #313893;
    border-bottom: none;
  }

  .empower-chart-4-srilanka-paragraph-column-4-new {
    width: 100%;
    text-align: center;
  }

  .empower-chart-4-srilanka-paragraph-column-5-new {
    width: 100%;
    text-align: center;
  }

  .empower-chart-4-srilanka-paragraph-column-6 {
    width: 100%;
    text-align: center;
  }

  .empower-chart-4-srilanka-paragraph-column-1-new{
    border-bottom: none;
    width: 100%;
    padding: 20px;
    text-align: center;
    border-left: 1.4px solid #313893;
  }
}

.button-image {
  height: 100px;
  width: 50px;
  display: inline-block; /* Add this line */
  background-size: contain;
  background-repeat: no-repeat;
}

.button-image:hover {
  cursor: pointer;
}

.selected {
  background-size: contain !important;
}

.sri-lanka-text-box-1-opacity {
  background-color: transparent;
  width: 100%;
  /* color: #67007c; */
  color: #000055;
  font-size: 18px;
  font-weight: 700;
  opacity: 0.4;
  cursor: pointer;
}

.sri-lanka-text-box-2-opacity {
  opacity: 0.4;
  background-color: transparent;
  width: 100%;
  /* color: #b80101; */
  color: #000055;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.sri-lanka-text-box-3-opacity {
  opacity: 0.4;
  background-color: transparent;
  width: 100%;
  /* color: #00438f; */
  color: #000055;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.sri-lanka-text-box-4-opacity {
  opacity: 0.4;
  background-color: transparent;
  width: 100%;
  color: #000055;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.selected-text{
  opacity: 1;
}

.gender-topic {
  color: #313893;
  font-size: 20px;
  font-weight: 900;
  font-family: "GT America Trial Md";
}

.career-card-container-5 {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.card {
  flex: 1 1 auto;
  overflow: hidden;
  margin: 2.5px;
  margin-bottom: 20px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  justify-items: center;
  text-align: center;
}

.career-chart-img-2 {
  max-width: 100%;
}

.equlity-chart-1-sri_lanka-number-loader {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #ffffff;
}

.middle-topic {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
}

.chart-box {
  width: 100%;
}

@media screen and (max-width: 900px) {
 .chart-box {
  width: auto;
}
}