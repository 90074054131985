@font-face {
  font-family: "GT-America";
  src: url("../../fonts/GT-America-Compressed-Black-Trial.woff")
    format("truetype");
  font-style: normal;
}
/* font-family: 'GT-America', sans-serif; */

.paragraph-two-column {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  height: auto;
}

.paragraph-column-1 {
  width: 50%;
  padding-top: 15px;
}

.paragraph-column-2 {
  width: 50%;
  padding: 0px;
}

@media screen and (max-width: 1190px) {
  .paragraph-two-column {
    flex-direction: column;
    height: auto;
  }

  .paragraph-column-1 {
    width: 100%;
    padding: 20px;
  }

  .paragraph-column-2 {
    width: 100%;
    height: 100vh;
  }
}

@media screen and (max-width: 801px) {
  .paragraph-column-2 {
    height: 120vh;
  }
}

@media screen and (max-width: 770px) {
  .paragraph-column-2 {
    height: auto;
  }
}

/* nav-bar */
.renderpage {
  margin-top: 55px;
  text-align: start;
  height: auto;
  margin-left: -50%;
}

@media (max-width: 800px) {
  .renderpage {
    height: 650px;
    margin-left: 0;
  }
}

@media (max-width: 650px) {
  .renderpage {
    height: 640px;
    margin-left: 0;
  }
}

.navbar {
  width: 50%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  position: relative;
}

@media screen and (max-width: 1020px) {
  .navbar {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .navbar {
    padding-left: 30px;
  }

  .renderpage {
    height: 680px;
    margin-left: 0;
  }
}

@media screen and (max-width: 370px) {
  .renderpage {
    height: 720px;
    margin-left: 0;
  }
}

.tab-lable {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  line-height: 20px;
}

.tab-lable:hover {
  font-size: 20px;
}

.navbar ul {
  list-style-type: none;
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
}

.navbar li {
  flex: 1;
  padding: 15px;
  padding-bottom: 10px;
  cursor: pointer;
  color: #8f8f97;
  text-align: center;
  border: 1px solid #ffffff00;
}

.navbar li:hover {
  background-color: #ffffff00;
  color: #313893;
  border: 1px solid #ffffff00;
  border-bottom: 1px solid #ffffff00;
}

.navbar li.selected {
  background-color: #ffffff00;
  color: #313893;
  padding-bottom: 2px;
  border: none;
}

.navbar li.selected::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #313893;
}

.navbar li {
  position: relative;
}

.pic {
  max-width: 80%;
}

@media (max-width: 768px) {
  .pic {
    max-width: 100%;
  }
}

.zoom-button {
  color: #313893;
  border: 1px solid #313893;
  transition: transform 0.3s ease;
  padding: 10px;
  text-decoration: none;
}

.zoom-button:hover {
  transform: scale(1.08);
  color: rgb(21, 179, 139);
  border: 1px solid rgb(21, 179, 139);
}

@media screen and (max-width: 1050px) {
  .pc {
    display: none;
  }

  .mobile {
    display: block;
  }
}

@media screen and (min-width: 1051px) {
  .mobile {
    display: none;
  }

  .pc {
    display: block;
  }
}

.highlights-main-topic {
  font-family: "GT-America", sans-serif;
  color: #313893;
  font-size: 60px;
  margin-bottom: 25px;
}

.img-gap-s {
  margin-bottom: 60px;
}
