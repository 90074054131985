@font-face {
  font-family: "GT-America-st-tr-bold";
  src: url("../../../../../fonts/GT-America-Standard-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@media (max-width: 870px) {
  .aa-pc {
    display: none;
  }
}

@media (min-width: 870px) {
  .aa-mobile {
    display: none;
  }
}

.conserve-waste-chart-03-whole{
  width: 80%;
}

.conserve-waste-chart-03-paragraph-two-column {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  font-family: "GT-America-st-tr-bold";
}

.conserve-waste-chart-03-paragraph-column-1-new{
  width: 5%;
  border-bottom: 1.4px solid #313893;
}

.conserve-waste-chart-03-paragraph-column-2-new {
  width: 95%;
  border-left: 1.4px solid #313893;
  border-bottom: 1.4px solid #313893;
  border-right: none;
  padding: 10px 0;
}

.conserve-waste-chart-03-paragraph-column-3-new{
  text-align: start;
  display: flex;
  justify-content: center;
  width: 30%;
}

.conserve-waste-chart-03-paragraph-column-4-new {
  width: 5%;
}

.conserve-waste-chart-03-paragraph-column-5-new {
  width: 95%;
  border-left: 1.4px solid #313893;
  border-right:none;
  display: flex;
  flex-direction: row;
  justify-content: space-around; 
}

.conserve-waste-chart-03-paragraph-column-6 {
  width: 30%;
}

@media screen and (max-width: 870px) {
  .conserve-waste-chart-03-paragraph-two-column {
    flex-direction: column;
    height: auto;
    width: 100%;
  }

  .conserve-waste-chart-03-paragraph-column-2-new {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-right: none;
    border-left: 1.4px solid #313893;
  }

  .conserve-waste-chart-03-paragraph-column-3-new {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-left: 1.4px solid #313893;
    border-bottom: none;
  }

  .conserve-waste-chart-03-paragraph-column-4-new {
    width: 100%;
    text-align: center;
  }

  .conserve-waste-chart-03-paragraph-column-5-new {
    width: 100%;
    text-align: center;
  }

  .conserve-waste-chart-03-paragraph-column-6 {
    width: 100%;
    text-align: center;
  }

  .conserve-waste-chart-03-paragraph-column-1-new{
    width: 0%;

  }
}

.selected-text{
  opacity: 1;
}

.conserve-waste-chart-03-card-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.conserve-chart-heading{
  color: #313893;
  font-size: 16px;
  font-weight: 900;
  font-family: "GT America Trial Md";
  margin-bottom: 40px;
}

.conserve-waste-chart-03-card {
  flex: 1 1 auto;
  min-width: 110px;
  overflow: hidden;
  margin: 2.5px;
  margin-bottom: 20px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  justify-items: center;
  text-align: center;
}

.conserve-waste-chart-03-number {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3%;
  margin-bottom: 15px;
}

.conserve-waste-chart-03-number.number-content-01{
  /* color: #00438f; */
  color: #000055;
}

.conserve-waste-chart-03-number.number-content-02{
  /* color: rgb(30, 109, 84); */
  color: #000055;
}

.conserve-waste-chart-03-letter{
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3%;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.conserve-waste-chart-03-letter.letter-content-01 h4{
  margin-top: auto;
  margin-bottom: auto;
}

.conserve-waste-chart-03-letter-icons{
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.conserve-waste-chart-03-letter-icon{
  opacity: 50%;
  /* color: #00438f; */
  color: #000055;
}

.selected-icon{
  opacity: 100%;
}

.conserve-waste-chart-03-letter.letter-content-01 h4{
  /* color: #00438f; */
  color: #000055;
  font-size: 18px;
}

.conserve-waste-chart-03-letter.letter-content-02 h4{
  /* color: rgb(30, 109, 84); */
  color: #000055;
  font-size: 18px;
}

@media (max-width: 870px) {
  .conserve-waste-chart-03-paragraph-column-5-new{
    margin-top: -22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .conserve-waste-chart-03-letter{
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .conserve-waste-chart-03-whole{
    width: 90%;
  }
}