@font-face {
  font-family: "GT America Trial Cm Bl";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bl"),
    url("../../fonts/GT-America-Compressed-Black-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Lt"),
    url("../../fonts/GT-America-Standard-Light-Trial.woff") format("woff");
}

.partnerships-stries-container {
  background-color: #ffffff;
  padding: 80px 70px 80px;
}

.partnerships-story-underline {
  width: 100%;
  height: 2px;
  background-color: #212529;
}

.para-partnership {
  font-family: "GT America Trial Lt";
}

.partnerships-story-contents-container {
  width: 80%;
}

.partnerships-story-contents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: start;
  margin-bottom: 150px;
}

.partnerships-story-content-left {
  width: 45%;
}

.partnerships-story-content-right {
  width: 45%;
}

.partnerships-story-content-right img {
  width: 95%;
  object-fit: cover;
}

.partnerships-story-contents:nth-of-type(2) {
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 20px;
}

.partnerships-story-topic h1 {
  font-family: "GT America Trial Cm Bl";
  font-size: 66px;
}

/*------------------------------------ Media Query --------------------------------*/
@media (max-width: 1000px) {
  .partnerships-stries-container {
    padding: 80px 70px 10px;
  }
}

@media (max-width: 768px) {
  .partnerships-story-contents {
    flex-direction: column;
    margin-bottom: 80px;
  }
}

@media (max-width: 600px) {
  .partnerships-story-topic h1 {
    font-size: 50px;
  }
  
  .partnerships-stries-container {
    padding: 80px 20px 20px;
  }
}

.partnerships-two-column {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: relative;
  justify-content: center;
  align-items: center;
}

.partnerships-column-1 {
  width: 50%;
  padding: 10px;
}

.partnerships-column-2 {
  width: 50%;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .partnerships-two-column {
    flex-direction: column;
  }

  .partnerships-column-1,
  .partnerships-column-2 {
    width: 100%;
  }
}

.para-partnership {
  padding: 60px;
}

.partnerships-img {
  max-width: 100%;
  padding: 100px;
  padding-top: 60px;
  padding-bottom: 0;
}

.para-partnership-1 {
  font-size: 20px;
}

.para-partnership-2 {
  font-size: 17px;
}

@media (max-width: 768px) {
  .para-partnership {
    padding: 0px;
  }

  .partnerships-img {
    max-width: 100%;
    padding: 0px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
