@font-face {
  font-family: "GT-America-st-tr-bold";
  src: url("../../../../fonts/GT-America-Standard-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT-America-st-tr-bold";
  src: url("../../../../fonts/GT-America-Standard-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT-America-2";
  src: url("../../../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT America Trial Cm Bd";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bd"),
    url("../../../../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}

.empower-wellbeing-chart-2trust-card-container-1 {
  display: flex;
  justify-content: center;
  font-family: "GT-America-st-tr-bold";
  /* font-family: "GT America Trial Cn Lt"; */
  width: 100%;
}

.card {
  flex: 1 1 auto;
  margin-bottom: 20px;
  overflow: hidden;
  margin: 2.5px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  justify-items: center;
  text-align: center;
}

.trust-chart-img-2 {
  max-width: 100%;
}

.empower-wellbeing-chart-02-chart-heading{
  color: #ffffff;
  /* font-size: 20px; */
  font-size: 1.75rem;
  font-weight: 900;
  font-family: "GT America Trial Md";
  /* font-family: "GT America Trial Cm Bd"; */
  margin-bottom: 70px;
}

.chart-1-t-c {
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #000055;
}

.chart-2-t-c {
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #000055;
}

.sri_lanka-number-loader {
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #ffffff;
  z-index: 20;
}

.trust-para-5-female {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #00438f;
}

.empower-wellbeing-chart-2-chart-box {
  width: 100%;
}

@media screen and (max-width: 900px) {
  .chart-box {
    width: auto;
  }
}

.num-c-2 {
  font-size: 30px;
}

.empower-wellbeing-chart-2-conatiner{
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.empower-wellbeing-chart-2-country-name-container{
  height: 250px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: start;
  user-select: none;
}


@media (max-width:470px) {
  .empower-wellbeing-chart-2-conatiner{
    flex-direction: column;
  }

  .empower-wellbeing-chart-2-country-name-container{
    text-align: center;
  }
}