.g-card-grid {
  display: grid;
  grid-template-columns: repeat(5, 150px);
  /* grid-template-columns: repeat(6, 150px); */
  grid-gap: 20px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.g-card {
  width: 150px;
  height: 150px;
  font-family: "Assistant", sans-serif;
  border-radius: 20px;
  text-align: center; /* Center align text */
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  transition: transform 0.3s ease; /* Add transition for smooth effect */
  text-decoration: none;
  cursor: pointer;
}

.link {
  text-decoration: none;
}

.link a {
  text-decoration: none;
}

/* Zoom effect on hover */
.g-card:hover {
  transform: scale(1.1); /* Scale up by 10% on hover */
}

@media (max-width: 768px) {
  .g-card-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 600px) {
  .g-card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card-title {
  font-size: 20px;
  font: bold;
  color: #fff;
  font-weight: 700;
}
