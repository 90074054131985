@font-face {
  font-family: "GT-America";
  src: url("../../../fonts/GT-America-Compressed-Black-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT-America-st-tr-bold";
  src: url("../../../fonts/GT-America-Standard-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

.trust-chart-1-paragraph-two-column {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  font-family: "GT-America-st-tr-bold";
}

@media screen and (max-width: 900px) {
  .trust-chart-1-paragraph-two-column {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .trust-chart-1-paragraph-column-2 {
    width: 100%;
  }
}

@media screen and (max-width: 1050px) {
  .pc {
    display: none;
  }

  .mobile {
    display: block;
  }
}

@media screen and (min-width: 1051px) {
  .mobile {
    display: none;
  }

  .pc {
    display: block;
  }
}

.trust-chart-1-highlights-main-topic {
  font-family: "GT-America", sans-serif;
  color: #313893;
  font-size: 60px;
  margin-bottom: 10px;
}

.trust-chart-1-img-gap-s {
  margin-bottom: 50px;
}

/* /// */
.chart-1-country-list {
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  text-align: center;
}

.para-1-flag {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

.para-1-flag h3 {
  margin-top: 5px;
  font-size: 18px;
  text-decoration: none;
  /* color: #67007c40; */
  color: #313893;
}

.para-1-flag h3:hover {
  /* color: #67007c85; */
  color: #000055;
}

.para-1-flag h3.selected {
  /* color: #67007c; */
  color: #000055;
  font-weight: 900;
}

.para-2-flag {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

.para-2-flag h3 {
  margin-top: 5px;
  font-size: 18px;
  text-decoration: none;
  /* color: rgba(17, 107, 79, 0.4); */
  color: #313893;
}

.para-2-flag h3:hover {
  /* color: rgba(17, 107, 79, 0.85); */
  color: #000055;
}

.para-2-flag h3.selected {
  /* color: rgb(17, 107, 79); */
  color: #000055;
  font-weight: 900;
}

.para-3-flag {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

.para-3-flag h3 {
  margin-top: 5px;
  font-size: 18px;
  text-decoration: none;
  /* color: #b8010140; */
  color: #313893;
}

.para-3-flag h3:hover {
  /* color: #b8010185; */
  color: #000055;
}

.para-3-flag h3.selected {
  /* color: #b80101; */
  color: #000055;
  font-weight: 900;
}

.para-4-flag {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

.para-4-flag h3 {
  margin-top: 5px;
  font-size: 18px;
  text-decoration: none;
  /* color: #00438f40; */
  color: #313893;
}

.para-4-flag h3:hover {
  /* color: #00438f85; */
  color: #000055;
}

.para-4-flag h3.selected {
  /* color: #00438f; */
  color: #000055;
  font-weight: 900;
}

.main-topic-chart-1-p {
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  color: rgb(49, 56, 147);
  font-family: "GT America Trial Md";
}

.topic-chart-1-p {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: rgb(49, 56, 147);
  font-family: "GT America Trial Md";
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .trust-chart-1-paragraph-two-column {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }
}

@media (max-width: 700px) {
  .e-pc {
    display: none;
  }
}

@media (min-width: 701px) {
  .e-mobile {
    display: none;
  }
}
