@font-face {
  font-family: "GT America Trial Cm Bl";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bl"),
    url("../../fonts/GT-America-Compressed-Black-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Md";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Md"),
    url("../../fonts/GT-America-Standard-Medium-Trial.woff") format("woff");
}

.new-conserve-chart-container-1 {
  padding: 0px;
  background-color: #fff;
  /* background-color: #e5f3f5; */
  overflow: hidden;
}

.bolder-box-line {
  padding: 60px;
  border: 1px solid #c5f1f7;
  padding-bottom: 0px;
  border-bottom: 0;
  padding-left: 80px;
}

.bolder-box-line-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bolder-box-line-content-left {
  width: 50%;
  padding: 80px;
  border: 1px solid #c5f1f7;
}

.bolder-box-line-content-right {
  width: 50%;
  padding: 80px;
  border: 1px solid #c5f1f7;
  border-left: 0px;
}

@media (max-width:700px) {
  .bolder-box-line-content-right {
    padding: 80px 50px;
  }
}

@media (max-width: 620px) {
  .bolder-box-line-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .bolder-box-line-content-left {
    width: 100%;
    border-bottom: 0px;
  }

  .bolder-box-line-content-right {
    width: 100%;
    padding: 80px;
  }
}

/* /// */
.bolder-box-line-content-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bolder-box-line-content-left-2 {
  width: 50%;
  padding: 80px;
  border: 1px solid #c5f1f7;
  border-top: 0px;
}

.bolder-box-line-content-right-2 {
  width: 50%;
  padding: 80px;
  border: 1px solid #c5f1f7;
  border-top: 0px;
  border-left: 0px;
}

@media (max-width: 620px) {
  .bolder-box-line-content-2 {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .bolder-box-line-content-left-2 {
    width: 100%;
    border-bottom: 0px;
  }

  .bolder-box-line-content-right-2 {
    width: 100%;
  }
}

.new-conserve-header-contents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
}

.new-conserve-header-content-left-2 {
  width: 100%;
}

.new-conserve-header-content-right-2 {
  width: 0%;
}

.new-conserve-header-contents * {
  color: #313893;
}

.new-conserve-header-content-left-2 h1 {
  font-family: "GT America Trial Cm Bl";
  font-size: 66px;
  text-transform: uppercase;
}

.new-conserve-header-content-right-2 p {
  font-family: "GT America Trial Md";
}

.bolder-box-line-content-left h1 {
  font-family: "GT America Trial Cm Bd";
  font-size: 66px;
  text-transform: uppercase;
  /* color: #000055; */
  color: #313893;
}

.bolder-box-line-content-left h4 {
  font-family: "GT America Trial Md";
  /* color: #000055; */
  color: #313893;
  font-size: 20px;
}

.bolder-box-line-content-left p {
  font-family: "GT America Trial Md";
  /* color: #000055; */
  color: #313893;
}

.new-conserve-bar-chart {
  width: 100%;
  margin-bottom: 50px;
}

.empower-target-charts {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
  width: 100%;
}

/*----------------------------------- Media Query--------------------------------------------- */
@media (max-width: 1115px) {
  .new-conserve-header-content-left-2 h1 {
    font-size: 46px;
  }
}

@media (max-width: 920px) {
  .new-conserve-header-content-left-2 h1 {
    font-size: 36px;
  }

  .empower-target-charts {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 800px) {
  .new-conserve-chart-container {
    padding: 80px 60px;
  }
}

@media (max-width: 700px) {
  .new-conserve-chart-container {
    padding: 80px 30px;
  }
}

@media (max-width: 620px) {
  .new-conserve-header-contents {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .new-conserve-header-content-left-2 {
    width: 100%;
    margin-bottom: 30px;
  }

  .new-conserve-header-content-right-2 {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .new-conserve-header-contents {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .new-conserve-header-content-left-2 {
    width: 100%;
    margin-bottom: 30px;
  }

  .new-conserve-header-content-right-2 {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .new-conserve-chart-container {
    padding: 80px 10px;
  }
}
