.energy_report-card {
  position: relative;
  width: 100%;
  height: 620px;
  background-size: cover;
  background-position: center;
}

@media (max-width: 768px) {
  .energy_report-card {
    height: 600px;
  }
}

@media (max-width: 600px) {
  .energy_report-card {
    height: 900px;
  }
}

.energy_report-card-content {
  position: relative;
  z-index: 1;
  padding: 50px;
}

.energy_report-card-grid {
  margin-top: 0px;
}

@media (min-width: 1111px) {
  /* PC layout: */
  .energy_report-card-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1110px) {
  /* Mobile layout: */
  .energy_report-card-grid {
    display: block;
  }
}

.targetHeading {
  color: #313893;
  font-family: "GT America Trial Cm Bd", sans-serif;
  font-size: 70px;
  line-height: 70px;
}

.targetPara {
  font-family: "GT America Trial Cn Lt", sans-serif;
  font-size: 19px;
  line-height: 25px;
  color: white;
}

.targetbutton {
  background-color: #313893;
  color: #fff;
  padding: 12px;
  padding-right: 35px;
  padding-left: 35px;
  font-size: 16px;
  line-height: 14px;
  font-weight: 600;
  border-radius: 50px;
  text-decoration: none;
  border: 2px solid #313893;
  font-family: "GT America Trial Cn Lt", sans-serif;
}

.targetbutton:hover {
  background-color: #ffffff;
  color: #313893;
  text-decoration: none;
  border: 2px solid #ffffff;
}

