@font-face {
    font-family: "GT America Trial Cm Bl";
    font-style: normal;
    font-weight: normal;
    src: local("GT America Trial Cm Bl"),
      url("../../../fonts/GT-America-Compressed-Black-Trial.woff") format("woff");
  }
  
  @font-face {
    font-family: "GT America Trial Md";
    font-style: normal;
    font-weight: normal;
    src: local("GT America Trial Md"),
      url("../../../fonts/GT-America-Standard-Medium-Trial.woff") format("woff");
  }

  .new-chemical-cha-hightlight-chart-container {
  padding: 0px;
  background-color: #fff;
  overflow: hidden;
}

.new-chemical-cha-hightlight-header-contents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
  color: #313893;
}

@media (max-width: 620px) {
  .new-chemical-cha-hightlight-header-contents {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .new-chemical-cha-hightlight-header-contents {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.new-chemical-cha-hightlight-bolder-box-line {
  padding: 60px;
  border: 1px solid #c5f1f7;
  padding-bottom: 0px;
  padding-left: 80px;
}

.new-chemical-cha-hightlight-bolder-box-line.conserve-chemical-chart-03 h4{
  font-size: 20px;
}

.new-chemical-cha-hightlight-bolder-box-line-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "GT America Trial Md";
  color: #313893;
}

.new-chemical-cha-hightlight-bolder-box-line-content-left {
  width: 50%;
  padding: 80px;
  border: 1px solid #c5f1f7;
  border-top: 0;
}

.new-chemical-cha-hightlight-bolder-box-line-content-left h4{
  font-size: 20px;
}

.new-chemical-cha-hightlight-bolder-box-line-content-right {
  width: 50%;
  padding: 80px;
  border: 1px solid #c5f1f7;
  border-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
  border-top: 0;
}

@media (max-width: 620px) {
  .new-chemical-cha-hightlight-bolder-box-line {
    border-right: 0px;
    border-left: 0px;
  }

  .new-chemical-cha-hightlight-bolder-box-line-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .new-chemical-cha-hightlight-bolder-box-line-content-left {
    width: 100%;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
  }

  .new-chemical-cha-hightlight-bolder-box-line-content-right {
    width: 100%;
    border-right: 0px;
    border-left: 0px;
  }
}

  
  .new-chemical-chart-container-2 {
    padding: 80px 100px;
    background-color: #fff;
  }
  
  .new-chemical-header-contents {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .new-chemical-header-content-left {
    width: 40%;
  }
  
  .new-chemical-header-content-right {
    width: 60%;
  }
  
  .new-chemical-header-contents * {
    color: #313893;
  }
  
  .new-chemical-header-content-left h1 {
    font-family: "GT America Trial Cm Bl";
    font-size: 66px;
    text-transform: uppercase;
  }
  
  .new-chemical-header-content-right p {
    font-family: "GT America Trial Md";
  }
  
  .new-chemical-bar-chart {
    width: 100%;
    margin-bottom: 50px;
  }
  
  .new-chemical-pie-charts {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 100px;
    margin-bottom: 50px;
    width: 100%;
  }
  
  .new-chemical-chart-margin-bottom{
    margin-bottom: 20px;
  }
  
  /*----------------------------------- Media Query--------------------------------------------- */
  @media (max-width: 1115px) {
    .new-chemical-header-content-left h1 {
      font-size: 46px;
    }
  
    .new-chemical-pie-charts {
      justify-content: space-between;
      padding: 0;
    }
  }
  
  @media (max-width: 920px) {
    .new-chemical-header-content-left h1 {
      font-size: 36px;
    }
  
    .new-chemical-pie-charts {
      flex-direction: column;
      gap: 20px;
    }
  }
  
  @media (max-width: 800px) {
    .new-chemical-chart-container-2 {
      padding: 80px 60px;
    }
  }
  
  @media (max-width: 700px) {
    .new-chemical-chart-container-2 {
      padding: 80px 30px;
    }
  }
  
  @media (max-width: 620px) {
    .new-chemical-header-contents {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .new-chemical-header-content-left {
      width: 100%;
      margin-bottom: 30px;
    }
  
    .new-chemical-header-content-right {
      width: 100%;
    }
  }
  
  @media (max-width: 500px) {
    .new-chemical-header-contents {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .new-chemical-header-content-left {
      width: 100%;
      margin-bottom: 30px;
    }
  
    .new-chemical-header-content-right {
      width: 100%;
    }
  }
  
  @media (max-width: 400px) {
    .new-chemical-chart-container-2 {
      padding: 80px 10px;
    }
  }
  
  .chart-chemical-para {
    width: 90%;
    margin-top: 250px;
    text-align: start;
  }
  
  .chemical-chart-two-column {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .chemical-chart-column-1 {
    width: 60%;
    padding: 10px;
  }
  
  .chemical-chart-column-2 {
    width: 40%;
    padding: 10px;
  }
  
  @media screen and (max-width: 1111px) {
    .chemical-chart-two-column {
      flex-direction: column;
    }
  
    .chemical-chart-column-1,
    .chemical-chart-column-2 {
      width: 100%;
    }
  
    .chart-chemical-para {
      margin-top: 0px;
    }
  }
  