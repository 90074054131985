@font-face {
  font-family: "GT-America-2";
  src: url("../../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT America Trial Cm Bd";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bd"),
    url("../../../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}

/* section-1 */
.environment-topic-home {
  font-size: 120px;
  color: #ffffff;
  margin-left: 80px;
  font-family: "GT-America-2", sans-serif;
}

.home-video {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}

.home-page-video {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
/* section-1 end */

.screen-page {
  width: 100%;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .screen-page {
    overflow-y: hidden;
  }
}

/* video topic */
.environment-text-box {
  height: 100px;
  text-align: start;
  z-index: 1;
  position: absolute;
  top: 40vh;
}

@media (max-width: 768px) {
  .environment-text-box {
    width: 90%;
    margin-left: 0px;
    top: 45vh;
  }

  .environment-topic-home {
    font-size: 150px;
    margin-left: 20px;
  }
}

@media (max-width: 600px) {
  .environment-text-box {
    width: 90%;
    margin-left: 0px;
    top: 55vh;
  }

  .environment-topic-home {
    font-size: 67px;
    margin-left: 0px;
  }
}

@media (max-width: 350px) {
  .environment-topic-home {
    font-size: 55px;
  }
}

@media (max-width: 300px) {
  .environment-topic-home {
    font-size: 45px;
  }
}

/* section-2 */
.environment-animation-para {
  background-color: #313893;
  color: #ffffff;
  width: 100%;
  padding: 80px 60px;
  font-size: 17px;
  display: flex;
  align-items: center;
}

.environment-animation-para-center {
  text-align: left;
  width: 100%;
  margin: auto;
}

.environment-animation-para-center p {
  font-size: 20px;
  /* font-family: "Arial Narrow", Arial, sans-serif; */
  font-family: "GT America Trial Cn Lt";
}

@media (max-width: 1150px) {
  .environment-animation-para {
    height: auto;
  }
}
