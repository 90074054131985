.arrow-lines {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  margin-top: -80.5vh;
}

@media (max-width: 1190px) {
  .arrow-lines {
    height: 0;
    width: 0;
  }
}

.blue-arrow {
  transform: rotate(2deg);
  position: absolute;
  right: 8%;
  bottom: 0;
  z-index: 1;
}

.green-arrow {
  transform: rotate(10deg);
  position: absolute;
  right: 20%;
  bottom: 0;
  z-index: 0.5;
}

@media (max-width: 768px) {
  .blue-arrow {
    transform: rotate(3deg);
    position: absolute;
    right: -20%;
    bottom: -10px;
  }

  .green-arrow {
    transform: rotate(10deg);
    position: absolute;
    right: -3%;
    bottom: -0px;
  }

  .arrow-lines {
    margin-top: -110vh;
    height: 110vh;
    width: 100%;
  }
}

@media (max-width: 600px) {
  /* .blue-arrow {
    right: -102%;
    bottom: -33px;
    transform: rotate(5deg);
  }

  .green-arrow {
    transform: rotate(10deg);
    right: -82%;
    bottom: -10px;
  }

  .arrow-lines {
    margin-top: -110vh;
    height: 110vh;
    width: 100%;
  } */
  .arrow-lines {
    display: none;
  }
}

@media (max-width: 577px) {
  .blue-arrow {
    right: -108%;
  }

  .green-arrow {
    right: -87%;
  }
}

@media (max-width: 543px) {
  .blue-arrow {
    right: -115%;
  }

  .green-arrow {
    right: -95%;
  }
}

@media (max-width: 518px) {
  .blue-arrow {
    right: -125%;
  }

  .green-arrow {
    right: -105%;
  }
}

@media (max-width: 476px) {
  .blue-arrow {
    right: -135%;
  }

  .green-arrow {
    right: -115%;
  }
}

@media (max-width: 443px) {
  .blue-arrow {
    right: -145%;
  }

  .green-arrow {
    right: -125%;
  }
}

@media (max-width: 407px) {
  .blue-arrow {
    right: -155%;
    transform: rotate(10deg);
    bottom: -60px;
  }

  .green-arrow {
    right: -135%;
  }
}

@media (max-width: 382px) {
  .blue-arrow {
    right: -155%;
    transform: rotate(10deg);
    bottom: -40px;
  }

  .green-arrow {
    right: -135%;
    bottom: 0px;
  }
}

@media (max-width: 370px) {
  .blue-arrow {
    right: -112%;
    bottom: -33px;
    transform: rotate(5deg);
  }

  .green-arrow {
    transform: rotate(10deg);
    right: -90%;
    bottom: -10px;
  }

  .arrow-lines {
    margin-top: -110vh;
    height: 114vh;
    width: 100%;
  }
}

@media (max-width: 360px) {
  .blue-arrow {
    right: -150%;
    bottom: -33px;
    transform: rotate(5deg);
  }

  .green-arrow {
    transform: rotate(10deg);
    right: -120%;
    bottom: -10px;
  }

  .arrow-lines {
    margin-top: -110vh;
    height: 114vh;
    width: 100%;
  }
}

@media (max-width: 360px) {
  .arrow-lines {
    height: 108.5vh;
  }
}

@media (max-width: 335px) {
  .arrow-lines {
    height: 105vh;
  }
}