.logos-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.logo-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-item img {
  max-width: 100%;
  max-height: 100px;
  position: relative;
  transition: transform 0.3s ease;
}

.logo-item img:hover {
  transform: scale(1.05);
}

/* // */
.logos-grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
}

.logo-item-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-item-2 img {
  max-width: 200px;
  max-height: 100px;
  position: relative;
  transition: transform 0.3s ease;
}

.logo-item-2 img:hover {
  transform: scale(1.05);
}

@media (max-width: 600px) {
  .logo-item-2 img {
    max-width: 100%;
    max-height: 100px;
    position: relative;
    transition: transform 0.3s ease;
  }
}

.bv {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  margin: 50px;
}

@media (max-width: 768px) {
  .bv {
  margin: 0px;
}
}