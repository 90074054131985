@font-face {
    font-family: 'GT America Trial Cm Bl';
    font-style: normal;
    font-weight: normal;
    src: local('GT America Trial Cm Bl'), url('../../../fonts/GT-America-Compressed-Black-Trial.woff') format('woff');
}

@font-face {
    font-family: 'GT America Trial Md';
    font-style: normal;
    font-weight: normal;
    src: local('GT America Trial Md'), url('../../../fonts/GT-America-Standard-Medium-Trial.woff') format('woff');
}

.new-water-page-hightlight-chart-container {
  padding: 0px;
  background-color: #ffffff;
  overflow: hidden;
}

.new-water-page-hightlight-chart-container {
  padding: 0px;
  background-color: #ffffff;
  overflow: hidden;
}

.new-water-page-hightlight-header-contents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
  color: #313893;
}

@media (max-width: 620px) {
  .new-water-page-hightlight-header-contents {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .new-water-page-hightlight-header-contents {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.new-chemical-chart-page-hightlight-bolder-box-line {
  padding: 60px;
  border: 1px solid #c5f1f7;
  padding-bottom: 0px;
  padding-left: 80px;
}

.new-chemical-chart-page-hightlight-bolder-box-line-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "GT America Trial Md";
  color: #313893;
}

.new-chemical-chart-page-hightlight-bolder-box-line-content-left {
  width: 50%;
  padding: 80px;
  border: 1px solid #c5f1f7;
  border-top: 0;
}

.new-chemical-chart-page-hightlight-bolder-box-line-content-left.conserve-water-chart-02 {
  padding: 80px 0px;
}

.new-chemical-chart-page-hightlight-bolder-box-line-content-right {
  width: 50%;
  padding: 80px;
  border: 1px solid #c5f1f7;
  border-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
  border-top: 0;
}

.new-chemical-chart-page-hightlight-bolder-box-line-content-right.conserve-water-chart-02-paragraph {
  padding: 80px;
}

@media (max-width: 620px) {
  .new-chemical-chart-page-hightlight-bolder-box-line {
    border-right: 0px;
    border-left: 0px;
  }

  .new-chemical-chart-page-hightlight-bolder-box-line-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .new-chemical-chart-page-hightlight-bolder-box-line-content-left {
    width: 100%;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
  }

  .new-chemical-chart-page-hightlight-bolder-box-line-content-right {
    width: 100%;
    border-right: 0px;
    border-left: 0px;
  }
}

.new-water-chart-container{
    padding: 80px 100px;
}

.new-water-header-contents{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.new-water-header-content-left{
    width: 40%;
}

.new-water-header-content-right{
    width: 60%;
}

.new-water-header-contents *{
    color: #313893;
}

.new-water-header-content-left h1{
    font-family: 'GT America Trial Cm Bl';
    font-size: 66px;
    text-transform: uppercase;
}

.new-water-header-content-right p{
    font-family: 'GT America Trial Md';
}

.new-water-bar-chart{
    width: 100%;
    margin-bottom: 50px;
}

.new-water-bar-chart-01{
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
}

.new-water-pie-charts{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 100px;
    margin-bottom: 50px;
    width: 100%;
}


/*----------------------------------- Media Query--------------------------------------------- */
@media (max-width:1115px) {
    .new-water-header-content-left h1{
        font-size: 46px;
    }

    .new-water-pie-charts{
        justify-content: space-between;
        padding: 0;
    }
    
}

@media (max-width:920px) {
    .new-water-header-content-left h1{
        font-size: 36px;
    }

    .new-water-pie-charts{
        flex-direction: column;
        gap: 20px;
    } 
}

@media (max-width:800px) {
    .new-water-chart-container{
        padding: 80px 60px;
    }
}

@media (max-width:700px) {
    .new-water-chart-container{
        padding: 80px 30px;
    }
}

@media (max-width:620px) {
    .new-water-header-contents{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .new-water-header-content-left{
        width: 100%;
        margin-bottom: 30px;
    }
    
    .new-water-header-content-right{
        width: 100%;
    }
}

@media (max-width:500px) {
    .new-water-header-contents{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .new-water-header-content-left{
        width: 100%;
        margin-bottom: 30px;
    }
    
    .new-water-header-content-right{
        width: 100%;
    }
}

@media (max-width:400px) {

    .new-water-chart-container{
        padding: 80px 10px;
    }
}

/* .chart-water-para {
    width: 90%;
    margin-top: 250px;
    text-align: start;
} */

.water-chart-two-column {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.water-chart-column-1 {
  width: 60%;
  padding: 10px;
  text-align: start;
}

.water-chart-column-2 {
  width: 40%;
  padding: 10px;
}

@media screen and (max-width: 1111px) {
  .water-chart-two-column {
    flex-direction: column;
  }

  .water-chart-column-1,
  .water-chart-column-2 {
    width: 100%;
  }

  .chart-water-para {
    margin-top: 0px;
}
}