@font-face {
  font-family: "GT-America-st-tr-bold";
  src: url("../../../../../fonts/GT-America-Standard-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

.career-employees-chart-topic-2 {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: rgb(49, 56, 147);
  font-family: "GT America Trial Md";
}

.career-card-container-2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-family: "GT-America-st-tr-bold";
}

.card.career-chart-02 {
  flex: 1 1 auto;
  overflow: hidden;
  margin: 2.5px;
  margin-bottom: 20px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  justify-items: center;
  text-align: center;
}

.career-chart-img-2 {
  max-width: 100%;
}

.career-number-loader-2-male {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 3%;
  /* color: #00438f; */
  color: #000055;
}

.career-para-2-male {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3%;
  /* color: #00438f; */
  color: #000055;
}

.career-number-loader-2-female {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 3%;
  /* color: #b80101; */
  color: #000055;
}

.career-para-2-female {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3%;
  /* color: #b80101; */
  color: #000055;
}
