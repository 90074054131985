@font-face {
  font-family: 'GT America Trial Cm Bl';
  font-style: normal;
  font-weight: normal;
  src: local('GT America Trial Cm Bl'), url('../../fonts/GT-America-Compressed-Black-Trial.woff') format('woff');
}

/* Archivo Google Font */
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap");

.stories-card-section-container * {
  font-family: "Archivo", sans-serif;
  box-sizing: border-box;
}

.stories-card-section-container {
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #b14fc5;
}

.stories-card-section-topic h1 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 66px;
  color: #ffffff;
  font-family: 'GT America Trial Cm Bl';
}

.stories-section-line {
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 20px;
}

.stories-section-cards {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.stories-section-card {
  /* width:calc(33.33%-20px); */
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  text-align: left;
  overflow: hidden;
  transition: 0.5s ease all;
}

.stories-section-card:hover {
  box-shadow: 13px 13px  #a427bd;
  border-radius: 5px;
}

.stories-section-card:hover img {
  scale: 1.1;
}

.stories-section-card-image {
  max-width: 100%;
  margin-bottom: 15px;
  max-height: 40%;
  overflow: hidden;
}

.stories-section-card-image img {
  width: 100%;
  transition: 0.5s ease all;
}

.stories-section-card-content {
  padding: 0 15px 20px 13px;
  display: flex;
  flex-direction: column;
  height: 60%;
}

.stories-section-card-content h5 {
  color: #212529;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 8px;
}

.stories-section-card-content p {
  color: #212529;
  margin-bottom: 18px;
}

.stories-section-card-content button {
  position: relative;
  align-items: center;
  display: flex;
  border: none;
  outline: none;
  background-color: #ffffff;
  font-weight: 500;
  align-self: flex-start;
  margin-top: auto;
  margin-bottom: 10px;
  transition: 0.5s ease all;
}

.stories-section-card-content-icon {
  transition: 0.5s ease all;
}

.stories-section-card-content button:hover .stories-section-card-content-icon {
  color: #165541;
  transform: translateX(-110px);
}

.stories-section-card-content button:hover {
  color: #165541;
  transform: translateX(30px);
}

/*------------------------------- Media Query ----------------------*/

@media (max-width: 1200px) {
  .stories-section-card-content {
    height: 70%;
  }
}

@media (max-width: 1000px) {
  .stories-section-card-content {
    height: 70%;
  }
}

@media (max-width: 770px) {
  .stories-section-cards {
    width: 80%;
    flex-direction: column;
    gap: 30px;
  }
}

@media (max-width: 430px) {
  .stories-section-cards {
    width: 100%;
  }
}

@media (max-width: 330px) {
  .new-home-stories-section-container {
    padding: 50px 25px;
  }
}
