
.groupWideInjuryPara {
    color: #313893;
    font-size: 20px;
    font-weight:800;
}

.boxWrapper {
    width: 70%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 29px;
    background-color: #fff;
    color: #444;
    margin-bottom: 36px;
}

.blueBox {
    background-color: #fff;
    color: #fff;
    border-radius: 5px;
    padding: 63px;
    font-size: 150%;
    position: relative;
    transition: transform 0.3s ease;
}
.blueBox:hover {
    transform: scale(1.05);
}

.backgroundImageContainer1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 119%; 
    background-image: url('../../../assets/Group1.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.backgroundImageContainer2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 119%; 
    background-image: url('../../../assets/Group2.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.backgroundImageContainer3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 119%; 
    background-image: url('../../../assets/Group3.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.backgroundImageContainer4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 119%; 
    background-image: url('../../../assets/Group4.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.backgroundImageContainer5 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 119%; 
    background-image: url('../../../assets/Group5.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.backgroundImageContainer6 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 119%; 
    background-image: url('../../../assets/Group6.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.backgroundImageContainer7 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 119%; 
    background-image: url('../../../assets/Group7.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.backgroundImageContainer8 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 119%; 
    background-image: url('../../../assets/Group8.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.backgroundImageContainer9 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 119%; 
    background-image: url('../../../assets/Group9.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}



/* Media query for smaller screens */

@media (max-width:1230px) {
    .boxWrapper {
        width: 80%;
    }
}

@media (max-width:1120px) {
    .boxWrapper {
        width: 90%;
    }
}

@media (max-width:1020px) {
    .boxWrapper {
        width: 100%;
    }
}

@media screen and (max-width: 935px) {
    .boxWrapper {
        grid-template-columns: repeat(2, 1fr); 
        grid-gap: 20px;
        padding: 10px; 
    }

    .blueBox {
        padding: 74px;
        font-size: 100%;
        margin-bottom: 18px;
    }
}

@media (max-width: 705px) {
    .boxWrapper {
        grid-template-columns: auto; 
    }
}

@media (max-width: 620px) {
    .boxWrapper {
        grid-template-columns: repeat(2, 1fr); 
        grid-gap: 20px;
        padding: 10px; 
    }
}

@media (max-width: 500px) {
    .boxWrapper {
        grid-template-columns: auto;
    }
}

/* Media query for even smaller screens */
/* @media screen and (max-width: 600px) {
    .boxWrapper {
        grid-template-columns: 1fr;
        grid-gap: 20px;
        padding: 10px;
    }

    .blueBox {
        padding: 30px;
        font-size: 100%; 
    }
} */
