/* Fonts */
@font-face {
  font-family: "GT-America";
  src: url("../../fonts/GT-America-Compressed-Black-Trial.woff")
    format("truetype");
  font-style: normal;
}

/* Styles for the image boxes and overlays */
.empower-button-image-box {
  position: relative;
  height: 350px;
  width: 250px;
  overflow: hidden;
  border-radius: 10px;
}

/* Background image */
.empower-button-image {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
}

/* Hover effect for the background image */
.empower-button-image-box:hover .empower-button-image {
  transform: scale(1.1);
  filter: brightness(0.6);
}

/* Overlay styles */
.empower-topic-overlay {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
  font-family: "GT-America", sans-serif;
  transition: transform 0.3s ease;
  pointer-events: none;
  font-size: calc(1.375rem + 0.8vw);
}

.empower-topic-overlay h1 {
  font-size: 10px;
}

/* Grid container styles */
.empower-icon-container {
  display: grid;
  grid-template-columns: repeat(3, 250px);
  grid-template-rows: auto;
  gap: 20px;
  justify-content: center;
}

/* Adjustments for smaller screens */
@media (max-width: 960px) {
  .empower-icon-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .empower-icon-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.empower-button-image-1-box {
  width: 100%;
  transition: transform 0.3s ease;
  filter: brightness(0.9);
  height: 350px;
  background-image: url("../../assets/e1.webp");
  background-size: cover;
}

.empower-button-image-2-box {
  width: 100%;
  transition: transform 0.3s ease;
  filter: brightness(0.9);
  height: 350px;
  background-image: url("../../assets/e2.webp");
  background-size: cover;
}

.empower-button-image-3-box {
  width: 100%;
  transition: transform 0.3s ease;
  filter: brightness(0.9);
  height: 350px;
  background-image: url("../../assets/e3.webp");
  background-size: cover;
}

.empower-ban-gap {
  width: 100%;
  margin-left: -120px;
}

@media (max-width: 960px) {
  .empower-ban-gap {
    width: 80%;
  margin-left: 0px;
  }
}
