@font-face {
  font-family: "GT-America-2";
  src: url("../../../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT America Trial Cm Bd";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bd"),
    url("../../../../fonts/GT-America-Compressed-Bold-Trial.woff")
      format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../../../fonts/GT-America-Condensed-Light-Trial.woff")
      format("woff");
}

@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap");

/* section-1 */
.spotlight-topic-home {
  font-size: 120px;
  color: #ffffff;
  margin-left: 80px;
  font-family: "GT-America-2", sans-serif;
}

.spotlight-topic-home-spot {
  font-size: 120px;
  color: #ffffff;
  margin-left: 80px;
  font-family: "GT-America-2", sans-serif;
}

.spotlight-background {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.spotlight-text-box-2 {
  height: 100px;
  text-align: start;
  z-index: 1;
  position: absolute;
  top: 45vh;
}

@media (max-width: 768px) {
  .spotlight-text-box-2 {
    width: 90%;
    margin-left: 0px;
    top: 45vh;
  }

  .spotlight-topic-home-spot {
    font-size: 150px;
    margin-left: 20px;
  }
}

@media (max-width: 600px) {
  .spotlight-text-box-2 {
    width: 90%;
    margin-left: 0px;
    top: 75vh;
  }

  .spotlight-topic-home-spot {
    font-size: 100px;
    margin-left: 0px;
  }
}

@media (max-width: 400px) {
  .spotlight-topic-home-spot {
    font-size: 70px;
  }
}
/* section-1 end */

/* section-2 */
.spotlight-wow-gender-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #313893;
  padding-top: 20px;
}

.career-development-spotlight-wow-gender-section-left {
  width: 30%;
  background-color: #313893;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.career-development-spotlight-wow-gender-section-left-01 {
  width: 30%;
  background-color: #313893;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.career-development-spotlight-wow-gender-section-left-01 img {
  position: absolute;
  left: 25%;
  width: 150%;
  border-radius: 30px;
  object-fit: cover;
  border: 10px solid white;
}

.career-development-spotlight-wow-gender-section-left img {
  position: absolute;
  left: 25%;
  width: 150%;
  border-radius: 30px;
  object-fit: cover;
  border: 10px solid white;
}

/* Media Query */

@media (max-width: 1200px) {
  .career-development-spotlight-wow-gender-section-left img {
    top: 15%;
    left: 20%;
    width: 150%;
  }

  .career-development-spotlight-wow-gender-section-left-01 img {
    top: 20%;
    left: 20%;
    width: 150%;
  }
}

@media (max-width: 1100px) {
  .career-development-spotlight-wow-gender-section-left img {
    top: 20%;
    left: 20%;
    width: 150%;
  }
}

@media (max-width: 1050px) {
  .career-development-spotlight-wow-gender-section-left-01 img {
    top: 25%;
  }

  .career-development-spotlight-wow-gender-section-left img {
    top: 20%;
    left: 20%;
    width: 150%;
  }
}
@media (max-width: 990px) {
  .career-development-spotlight-wow-gender-section-left img {
    width: 140%;
    top: 25%;
  }
}

@media (max-width: 950px) {
  .career-development-spotlight-wow-gender-section-left img {
    top: 25%;
    left: 20%;
    width: 140%;
  }
}

@media (max-width: 900px) {
  .career-development-spotlight-wow-gender-section-left-01 img {
    width: 160%;
    top: 30%;
    left: 20%;
  }

  .career-development-spotlight-wow-gender-section-left img {
    width: 160%;
    top: 30%;
    left: 20%;
  }
}

@media (max-width: 720px) {
  .career-development-spotlight-wow-gender-section-left img {
    width: 160%;
  }
}

@media (max-width: 600px) {
  .career-development-spotlight-wow-gender-section-left img {
    position: relative;
    width: 60%;
    width: 90%;
    margin-left: -44%;
    margin-bottom: 20px;
  }

  .career-development-spotlight-wow-gender-section-left {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 0;
  }

  .career-development-spotlight-wow-gender-section-left-01 {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .career-development-spotlight-wow-gender-section-left-01 img {
    position: relative;
    width: 90%;
    margin-left: -44%;
    margin-bottom: 20px;
  }
}

.spotlight-wow-gender-section-right {
  width: 70%;
  background-color: #c4e9f9;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 100px 0;
  padding-bottom: 150px;
}

.spotlight-wow-gender-section-right-contents {
  width: 45%;
  margin-right: 8%;
}

.spotlight-wow-gender-section-right-contents h1 {
  text-transform: uppercase;
  color: #313893;
  font-family: "GT America Trial Cm Bd", sans-serif;
  font-size: 70px;
  line-height: 70px;
}

.small-heading h1 {
  font-size: 60px;
  line-height: 60px;
}

.spotlight-wow-gender-section-right-contents h2 {
  text-transform: uppercase;
  color: #313893;
  font-family: "GT America Trial Cm Bd", sans-serif;
  font-size: 45px;
  line-height: 45px;
}

.spotlight-wow-gender-section-right-contents p {
  font-family: "GT America Trial Cn Lt", sans-serif;
}

.flag-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
}

.flag-box {
  width: 80px;
}

.flag-box img {
  max-width: 100%;
}

.spotlight-wow-gender-section-right-contents button {
  position: relative;
  align-items: center;
  padding-left: 0;
  display: flex;
  border: none;
  outline: none;
  background-color: transparent;
  font-weight: 500;
  align-self: flex-start;
  margin-top: auto;
  margin-bottom: 10px;
  transition: 0.5s ease all;
}

.spotlight-wow-gender-icon {
  transition: 0.5s ease all;
}

.spotlight-wow-gender-section-right-contents
  button:hover
  .spotlight-wow-gender-icon {
  color: #313893;
  transform: translateX(-165px);
}

.spotlight-wow-gender-section-right-contents button:hover {
  color: #313893;
  transform: translateX(30px);
}

@media (max-width: 990px) {
  .spotlight-wow-gender-section-left-unknow img {
    width: 140%;
    margin-left: 130px;
  }
}

@media (max-width: 900px) {
  .spotlight-wow-gender-section-left-unknow img {
    width: 160%;
    margin-left: 50px;
  }

  .spotlight-wow-gender-section-right-contents {
    width: 50%;
    margin-right: 10%;
    padding: 60px 0;
  }
}

@media (max-width: 768px) {
  .spotlight-topic-home {
    font-size: 150px;
    margin-left: 20px;
  }
}

@media (max-width: 720px) {
  .spotlight-wow-gender-section-left-unknow img {
    width: 160%;
    margin-left: 50px;
    height: 100%;
  }

  .spotlight-wow-gender-section-right-contents {
    width: 50%;
    margin-right: 10%;
  }

  .spotlight-wow-gender-section-right-contents h1 {
    font-size: 35px;
    line-height: 35px;
  }
}

@media (max-width: 600px) {
  .spotlight-topic-home {
    font-size: 100px;
    margin-left: 0px;
  }

  .spotlight-wow-gender-section {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-left: 20px;
  }

  .spotlight-wow-gender-section-left-unknow {
    width: 100%;
  }

  .spotlight-wow-gender-section-right {
    width: 100%;
  }

  .spotlight-wow-gender-section-left-unknow img {
    width: 100%;
  }

  .spotlight-wow-gender-section-right {
    width: 100%;
    justify-content: center;
  }

  .spotlight-wow-gender-section-right-contents {
    width: 80%;
    margin-right: 0;
  }
}

@media (max-width: 400px) {
  .spotlight-wow-gender-section-left-unknow img {
    width: 100%;
  }
}
/* section-2 end */
