@font-face {
    font-family: "GT America Trial Cm Bd";
    font-style: normal;
    font-weight: normal;
    src: local("GT America Trial Cm Bd"),
    url("../../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
    font-family: "GT America Trial Cn Lt";
    font-style: normal;
    font-weight: normal;
    src: local("GT America Trial Cn Lt"),
    url("../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}


.message-box{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.road-map-messages{
    width: 95%;
    height: 95%;
    overflow: auto;
    border-radius: 5px;
    background-color: #fff;
    border: 4px solid #fff;
    animation: zoomIn 0.3s ease;
    z-index: 100;
    font-size: 18px;
}

@keyframes zoomIn {
    from {
        scale: 0.5;
    }
    to {
        scale: 1;
    }
}

.road-map-messages > p{
    color: black;
    position: relative;
    animation: slideToRight 1s ease;
}

@keyframes slideToRight {
    from {
        left: -50px;
        opacity: 0;
    }
    to {
        left: 0;
        opacity: 1;
    }
}

.message-box.hide{
    display: none;
}

.road-map-messages-header{
    display: flex;
    width: 94%;
    padding: 15px 20px;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    position: fixed;
    z-index: 100;
}

.road-map-message-title h3{
    color: #08313a;
    font-family: "GT America Trial Cm Bd";
}

.road-map-message-icon{
    cursor: pointer;
}

.road-map-message-contents{
    width: 85%;
    height: 100%;
    margin-top: 80px;
    margin-bottom: 15px;
    border: 2px solid #313893;
    border-radius: 5px;
    padding: 30px 50px 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-family: "GT America Trial Cn Lt";
}

.road-map-message-contents h5 {
    color: #08313a;
    opacity: 0;
    animation: fadeIn 1s ease forwards;
    animation-delay: 0.5s;
    font-weight: bolder;
}

@keyframes fadeIn {
    
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
    
}

.road-map-message-contents-p{
    width: 100%;
}

.road-map-message-contents-p p{
    opacity: 0;
    animation: fadeIn 1s ease forwards;
    animation-delay: 1s;
}

@media (max-width:492px) {
    .road-map-message-contents{
        margin-top: 100px;
    }
}

@media (max-width:450px) {
    .road-map-messages{
        width: 95%;
        height: 85%;
    }
}

@media (max-width:370px) {
    .road-map-message-contents-p{
        width: 100%;
    }
}

