@font-face {
  font-family: "GT-America-2";
  src: url("../../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT America Trial Cm Bd";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bd"),
    url("../../../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}


/* section-1 */
.childrin-education-topic-home {
  font-size: 120px;
  color: #ffffff;
  margin-left: 80px;
  font-family: "GT-America-2", sans-serif;
}

.home-video {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}

.home-page-video {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
/* section-1 end */

.screen-page {
  width: 100%;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .screen-page {
    overflow-y: hidden;
  }
}

/* video topic */
.childrin-education-text-box {
  height: 100px;
  text-align: start;
  z-index: 1;
  position: absolute;
  top: 40vh;
}

@media (max-width: 768px) {
  .childrin-education-text-box {
    width: 90%;
    margin-left: 0px;
    top: 45vh;
  }

  .childrin-education-topic-home {
    font-size: 150px;
    margin-left: 20px;
  }
}

@media (max-width: 665px) {
  .childrin-education-text-box {
    width: 90%;
    margin-left: 0px;
    top: 55vh;
  }

  .childrin-education-topic-home {
    font-size: 80px;
    margin-left: 0px;
  }
}

/* section-2 */
.childrin-education-page-para-2 {
  width: 100%;
  padding: 80px 60px;
  text-align: start;
  /* font-family: "Arial Narrow", Arial, sans-serif; */
  font-family: "GT America Trial Cn Lt";
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

@media (max-width: 800px) {
  .childrin-education-page-para {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .childrin-education-page-para {
    height: auto;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .childrin-education-page-para {
    height: 400px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .childrin-education-page-para {
    height: 450px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .childrin-education-page-para {
    height: 350px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .childrin-education-page-para {
    height: 350px;
  }
}

.childrin-education-page-para {
  display: flex;
  align-items: center;
}


@media (max-width:400px) {
  .childrin-education-page-para {
    padding: 100px 30px;
  }
}
