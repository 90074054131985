@font-face {
  font-family: "GT-America-2";
  src: url("../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT America Trial Cm Bd";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bd"),
    url("../../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}

.unsdg-section-container {
  display: flex;
  flex-direction: column;
}

.unsdg-section-container-left {
  width: 100%;
  padding: 80px;
  background-color: #3cb4e5;
}

@media (max-width: 600px) {
  .unsdg-section-container-left {
    padding: 20px;
  }
}

.unsdg-section-container-left h1 {
  color: #2b2b2b;
  font-weight: 700;
  font-size: 55px;
  line-height: 65px;
  font-family: "GT America Trial Cm Bd";
}

.unsdg-section-container-left p {
  line-height: 29px;
  color: white;
  margin-top: 20px;
  margin-bottom: 10%;
  font-family: "GT America Trial Lt";
}

.unsdg-section-container-right {
  width: 100%; /* Full width on mobile */
  background-color: #f0f0f1;
}

.unsdg-section-container-right-image {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
}

.unsdg-section-container-right-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Media Query for mobile screens */
@media (min-width: 768px) {
  .unsdg-section-container {
    flex-direction: row; /* Revert to row layout for larger screens */
  }

  .unsdg-section-container-left {
    width: 50%;
  }

  .unsdg-section-container-right {
    width: 50%;
  }
}
