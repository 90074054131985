@font-face {
  font-family: "GT-America-st-tr-bold";
  src: url("../../../../../fonts/GT-America-Standard-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

.conserve-water-chart-04-paragraph-two-column {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-family: "GT-America-st-tr-bold";
}

.conserve-water-chart-04-paragraph-column-02{
  width: 100%;
}

.conserve-chart-heading{
  color: #313893;
  font-size: 18px;
  font-weight: 900;
  font-family: "GT America Trial Md";
  margin-bottom: 70px;
}

.conserve-water-chart-04-card-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.conserve-water-chart-04-card {
  flex: 1 1 auto;
  min-width: 110px;
  overflow: hidden;
  margin: 2.5px;
  margin-bottom: 20px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  justify-items: center;
  text-align: center;
}

.conserve-water-chart-04-number {
  font-size: 16px;
  font-weight: 700;
}

.conserve-water-chart-04-number.number-content-01{
  /* color: #00438f; */
  color: #000055;
}

.conserve-water-chart-04-number.number-content-02{
  /* color: #e05e00; */
  color: #000055;
}

.conserve-water-chart-04-letter{
  font-weight: 700;
  margin-top: 15px;
}

.conserve-water-chart-04-letter.letter-content-01 h4{
  /* color: #00438f; */
  color: #000055;
  font-size: 16px;
}

.conserve-water-chart-04-letter.letter-content-02 h4{
  /* color: #e05e00; */
  color: #000055;
  font-size: 16px;
}