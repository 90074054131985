.cookie-notice-box {
  position: fixed;
  bottom: 30px;
  right: 40px;
  text-align: left;
  width: 480px;
  box-sizing: border-box;
  z-index: 9999999;
  background: #ffffff;
  padding: 20px 26px;
  box-shadow: 0 -1px 10px 0 #0000006c;
  border-radius: 6px;
  transition: 0.5s all;
}

.cookie-message {
  color: rgba(80, 78, 78, 0.875);
  /* font-family: 'Avenir Roman'; */
  font-family: 'GT America Standard Medium', sans-serif;
  font-size: 15px;
  line-height: 24px;
  margin-top: 10px;
}

.policy-link {
  color: #313893;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  transition: 0.2s all;
  text-decoration: none;
}

.policy-link:hover {
  color: #1c5258;
}

.accept-button {
  font-size: 15px;
  font-family: 'Lato', sans-serif;
  margin-top: 20px;
  width: 100%;
  height: 45px;
  color: #fff;
  background: #313893;
  line-height: 24px;
  cursor: pointer;
  border: none;
  transition: 0.2s all;
}

.accept-button:hover {
  background: #1c5258;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .cookie-notice-box {
    width: calc(100% - 2px);
    margin: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px;
    transform: none;
  }
}
