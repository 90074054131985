@font-face {
  font-family: "GT-America-2";
  src: url("../../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT America Trial Cm Bd";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bd"),
    url("../../../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}


/* section-2 */
.healthcare-animation-para {
  background-color: #313893;
  color: #ffffff;
  width: 100%;
  padding: 80px 60px;
  font-size: 17px;
  display: flex;
  align-items: center;
}

.healthcare-animation-para-center {
  text-align: left;
  width: 100%;
  margin: auto;
}

.healthcare-animation-para-center h1 {
  font-family: "GT America Trial Cm Bd";
}

.healthcare-animation-para-center p {
  /* font-family: "Arial Narrow", Arial, sans-serif; */
  font-family: "GT America Trial Cn Lt";
  font-size: 20px;
}

.healthcare-animation-para-center-yello-p {
  color: #f0ec73;
  padding-left: 50px;
}
/* section-2 end */
