@font-face {
  font-family: "GT-America-st-tr-bold";
  src: url("../../../../../fonts/GT-America-Standard-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@media (max-width: 870px) {
  .aa-pc {
    display: none;
  }
}

@media (min-width: 870px) {
  .aa-mobile {
    display: none;
  }
}

.conserve-water-chart-05-paragraph-two-column {
  display: flex;
  margin-top: 20px;
  width: 100%;
  font-family: "GT-America-st-tr-bold";
}

.conserve-water-chart-05-paragraph-column-1-new{
  width: 5%;
  border-bottom: 1.4px solid #313893;
}

.conserve-water-chart-05-paragraph-column-2-new {
  width: 65%;
  border-left: 1.4px solid #313893;
  border-bottom: 1.4px solid #313893;
  border-right: none;
  padding-top: 4%;
}

.conserve-water-chart-05-paragraph-column-3-new{
  text-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.conserve-water-chart-05-paragraph-column-4-new {
  width: 5%;
}

.conserve-water-chart-05-paragraph-column-5-new {
  width: 65%;
  border-left: 1.4px solid #313893;
  border-right:none;
  display: flex;
  justify-content: center;
  gap: 55%;
  align-items: center;
  padding: 20px 75px;
}

.conserve-water-chart-05-paragraph-column-6-new {
  width: 30%;
}

.conserve-water-chart-05-letter.letter-content-01{
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 10px;
}

.conserve-water-chart-05-letter-icons{
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.conserve-water-chart-05-letter-icon{
  opacity: 50%;
  color: #000055;
}

.selected-icon{
  opacity: 100%;
}

@media screen and (max-width: 870px) {
  .conserve-water-chart-05-paragraph-two-column {
    flex-direction: column;
    height: auto;
    width: 100%;
  }

  .conserve-water-chart-05-paragraph-column-2-new {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-right: none;
    border-left: 1.4px solid #313893;
    border-bottom: 1.4px solid #313893;
  }

  .conserve-water-chart-05-paragraph-column-3-new {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-left: 1.4px solid #313893;
    border-bottom: none;
  }

  .conserve-water-chart-05-paragraph-column-4-new {
    width: 100%;
    text-align: center;
  }

  .conserve-water-chart-05-paragraph-column-5-new {
    width: 100%;
    text-align: center;
  }

  .conserve-water-chart-05-paragraph-column-6 {
    width: 100%;
    text-align: center;
  }

  .conserve-water-chart-05-paragraph-column-1-new{
    border-bottom: none;
    width: 100%;
    padding: 20px;
    text-align: center;
    border-left: 1.4px solid #313893;
  }
}

.conserve-water-chart-05-paragraph-column-3-new img{
  width: 80px;
  height: 50px;
  margin-right: 25px;
}

.conserve-water-chart-05-paragraph-column-3-new h4{
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 9px;
  margin-top: 9px;
}

.conserve-water-chart-05-text-box-1-opacity {
  background-color: transparent;
  width: 100%;
  /* color: #091294; */
  color: #000055;
  opacity: 0.4;
  cursor: pointer;
}

.conserve-water-chart-05-text-box-1-opacity{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.conserve-water-chart-05-text-box-2-opacity {
  opacity: 0.4;
  background-color: transparent;
  width: 100%;
  /* color: #e96b12ee; */
  color: #000055;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.conserve-water-chart-05-text-box-2-opacity{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.conserve-water-chart-05-text-box-3-opacity {
  opacity: 0.4;
  background-color: transparent;
  width: 100%;
  /* color:#ee0505; */
  color: #000055;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.conserve-water-chart-05-text-box-3-opacity{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.conserve-water-chart-05-text-box-4-opacity {
  opacity: 0.4;
  background-color: transparent;
  width: 100%;
  /* color: #1c7401; */
  color: #000055;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.conserve-water-chart-05-text-box-4-opacity{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.conserve-water-chart-05-text-box-5-opacity {
  opacity: 0.4;
  background-color: transparent;
  width: 100%;
  /* color: #003775; */
  color: #000055;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.conserve-water-chart-05-text-box-5-opacity{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selected-text{
  opacity: 1;
}


@media (max-width:870px) {
  .conserve-water-chart-05-text-box-1-opacity{
    justify-content: center;
  }

  .conserve-water-chart-05-text-box-2-opacity{
    justify-content: center;
  }
  
  .conserve-water-chart-05-text-box-3-opacity{
    justify-content: center;
  }
  
  .conserve-water-chart-05-text-box-4-opacity{
    justify-content: center;
  }
  
  .conserve-water-chart-05-text-box-5-opacity{
    justify-content: center;
  }
}

.conserve-energy-chart-heading{
  color: #313893;
  font-size: 18px;
  font-weight: 900;
  font-family: "GT America Trial Md";
  margin-bottom: 70px;
}

.conserve-water-chart-05-card-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.conserve-water-chart-05-card {
  flex: 1 1 auto;
  min-width: 110px;
  margin-bottom: 20px;
  overflow: hidden;
  margin: 2.5px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  justify-items: center;
  text-align: center;
}

.conserve-water-chart-05-number {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3%;
  margin-bottom: 15px;
}

.conserve-water-chart-05-number.number-content-01{
  /* color: #9f00be; */
  color: #000055;
}

.conserve-water-chart-05-number.number-content-02{
  color: #000055;
}

.conserve-water-chart-05-letter{
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3%;
  margin-top: 15px;
}

.conserve-water-chart-05-letter.letter-content-01 h4{
  /* color: #0075a3; */
  color: #000055;
  font-size: 16px;
}

.conserve-water-chart-05-letter.letter-content-02 h4{
  color: #000055;
  font-size: 16px;
}

@media (max-width: 870px) {
  .conserve-water-chart-05-paragraph-column-5-new{
    margin-top: -23px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1.4px solid #313893;
  }

  .conserve-water-chart-05-letter{
    margin-bottom: 20px;
  }
}