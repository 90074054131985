.scroll-to-top-button {
  width: 50px;
  height: 50px;
  background-color: #ffffffbc;
  border: 1px solid #313893;
  color: #313893;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 100000;
  &.visible {
    opacity: 1;
  }
}

.scroll-to-top-button:hover {
  background-color: #74dcd2b4;
}
