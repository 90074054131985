@font-face {
  font-family: "GT-America-2";
  src: url("../../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT America Trial Cm Bd";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bd"),
    url("../../../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}

@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap");

/* section-1 */
.equality-topic-home {
  font-size: 120px;
  color: #ffffff;
  margin-left: 80px;
  font-family: "GT-America-2", sans-serif;
}

.equality-topic-home-spot {
  font-size: 120px;
  color: #ffffff;
  margin-left: 80px;
  font-family: "GT-America-2", sans-serif;
}

.home-page-background {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
/* section-1 end */

.screen-page {
  width: 100%;
  overflow-x: hidden;
  overflow: hidden;
}

.equality-text-box {
  height: 100px;
  text-align: start;
  z-index: 1;
  position: absolute;
  top: 40vh;
}

.euqality-paragraph-section-1 {
  background-color: #1e6d54;
  color: #ffffff;
  width: 100%;
  padding: 80px 0;
}

.euqality-paragraph-section-1-center {
  text-align: left;
  width: 100%;
  margin: auto;
  padding: 0 60px;
}

.euqality-paragraph-section-1-center p {
  margin-bottom: 20px;
  /* font-family: "Arial Narrow", Arial, sans-serif; */
  font-family: "GT America Trial Cn Lt";
  font-size: 20px;
}

.euqality-paragraph-section-1 {
  display: flex;
  align-items: center;
}

.equality-stacked-bar-chart-container {
  background-color: #ffffff;
  color: #313893;
  width: 100%;
  padding: 0;
}

/* section-3 */
.equality-cards {
  position: relative;
  background-color: #f5f5f5a0;
}

.equality-para-box {
  margin-left: 28%;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 20px;
  background-color: #ffffff;
}

.equality-card-width {
  overflow: hidden;
  position: relative;
  margin-top: -120px;
  width: 100%;
  z-index: 2;
  margin-left: -250px;
}

.equality-para-two {
  margin-left: -28.5%;
  font-size: 250px;
  color: #d2e1dd;
  font-family: "GT America Trial Cm Bl";
  z-index: 1;
}

.equality-wow-gender-section-01 {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.equality-wow-gender-section-01-left {
  width: 30%;
  background-color: #313893;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.equality-wow-gender-section-01-left img {
  position: absolute;
  left: 25%;
  width: 150%;
  border-radius: 30px; 
  border: 10px solid white;
}

.equality-wow-gender-section-01-right {
  width: 70%;
  background-color: #c4e9f9;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 100px 0;
}

/* Media Query */

@media (max-width: 1200px) {
  .equality-wow-gender-section-01-left img {
    left: 20%;
    width: 150%;
  }
}

@media (max-width: 1100px) {
  .equality-wow-gender-section-01-left img {
    left: 20%;
    width: 150%;
  }
}

@media (max-width: 1050px) {
  .equality-wow-gender-section-01-left img {
    left: 20%;
    width: 150%;
  }
}
@media (max-width: 990px) {
  .equality-wow-gender-section-01-left img {
    width: 140%;
  }
}

@media (max-width: 950px) {
  .equality-wow-gender-section-01-left img {
    left: 20%;
    width: 140%;
  }
}

@media (max-width: 900px) {
  .equality-wow-gender-section-01-left img {
    width: 160%;
    left: 20%;
  }
}

@media (max-width: 720px) {
  .equality-wow-gender-section-01-left img {
    width: 160%;
  }
}

@media (max-width: 600px) {
  .equality-wow-gender-section-01-left img {
    position: relative;
    width: 90%;
    margin-left: -44%;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .equality-wow-gender-section-01-left {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.equality-wow-gender-section-01-right-contents {
  width: 45%;
  margin-right: 8%;
  padding-bottom: 30px;
}

.equality-wow-gender-section-01-right-contents h1 {
  text-transform: uppercase;
  color: #313893;
  font-family: "GT America Trial Cm Bd", sans-serif;
  font-size: 70px;
  line-height: 70px;
}

.equality-wow-gender-section-01-right-contents p {
  font-family: "GT America Trial Cn Lt", sans-serif;
}

.equality-wow-gender-section-01-right-contents button {
  position: relative;
  align-items: center;
  padding-left: 0;
  display: flex;
  border: none;
  outline: none;
  background-color: transparent;
  font-weight: 500;
  align-self: flex-start;
  margin-top: auto;
  margin-bottom: 10px;
  transition: 0.5s ease all;
}

.equality-wow-gender-icon {
  transition: 0.5s ease all;
}

.equality-wow-gender-section-01-right-contents
  button:hover
  .equality-wow-gender-icon {
  color: #313893;
  transform: translateX(-165px);
}

.equality-wow-gender-section-01-right-contents button:hover {
  color: #313893;
  transform: translateX(30px);
}

.equality-stacked-bar-charts p {
  margin-bottom: 5%;
}

.equality-stacked-bar-chart {
  border-radius: 8px;
  overflow: hidden;
  height: 350px;
}

.euqality-paragraph-section-1-center-yello-p {
  color: #fff;
  padding-left: 50px;
  margin-top: -15px;
  list-style: disc;
  /* font-family: "Arial Narrow", Arial, sans-serif; */
  font-family: "GT America Trial Cn Lt";
  font-size: 20px;
}

.new-equality-chart-02-hightlight-bolder-box-line {
  padding: 60px;
  border: 1px solid #c5f1f7;
  padding-bottom: 0px;
  padding-left: 80px;
}

.new-equality-chart-02-hightlight-bolder-box-line-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "GT America Trial Md";
  color: #313893;
}

.new-equality-chart-02-hightlight-bolder-box-line-content-left {
  width: 50%;
  padding: 80px;
  border: 1px solid #c5f1f7;
  border-top: 0;
}

.new-equality-chart-02-hightlight-bolder-box-line-content-right {
  width: 50%;
  padding: 80px;
  border: 1px solid #c5f1f7;
  border-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
  border-top: 0;
}

@media (max-width: 620px) {
  .new-equality-chart-02-hightlight-bolder-box-line-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .new-equality-chart-02-hightlight-bolder-box-line-content-left {
    width: 100%;
    border-bottom: 0px;
    border-top: 1px solid #c5f1f7;
    padding: 50px;
  }

  .new-equality-chart-02-hightlight-bolder-box-line-content-right {
    width: 100%;
    border-top: 1px solid #c5f1f7;
    padding: 80px 40px;
  }
}


/*---------------- Media Queiry ------------------*/

@media (max-width: 990px) {
  .equality-para-two {
    font-size: 200px;
  }

  .equality-wow-gender-section-01-left img {
    width: 140%;
  }
}

@media (max-width: 950px) {
  .equality-wow-gender-section-01-left img {
    top: 20%;
    left: 20%;
    width: 140%;
  }
}

@media (max-width: 900px) {
  .equality-wow-gender-section-01-left img {
    width: 160%;
    top: 25%;
    left: 20%;
  }

  .equality-wow-gender-section-01-right-contents {
    width: 50%;
    margin-right: 10%;
    padding: 80px 0;
  }
}

@media (max-width: 820px) {
  .equality-para-two {
    font-size: 150px;
  }
}

@media (max-width: 768px) {
  .screen-page {
    overflow-y: hidden;
  }

  .equality-text-box {
    width: 90%;
    margin-left: 0px;
    top: 45vh;
  }

  .equality-topic-home {
    font-size: 150px;
    margin-left: 20px;
  }

  .equality-topic-home-spot {
    font-size: 150px;
    margin-left: 20px;
  }

  .equality-card-width {
    margin-top: -70px;
    margin-left: -200px;
  }

  .equality-para-two {
    font-size: 150px;
  }
}

@media (max-width: 720px) {
  .equality-wow-gender-section-01-left img {
    width: 160%;
  }

  .equality-wow-gender-section-01-right-contents {
    width: 50%;
    margin-right: 10%;
  }

  .equality-wow-gender-section-01-right-contents h1 {
    font-size: 50px;
    line-height: 50px;
  }
}

@media (max-width: 600px) {
  .equality-text-box {
    width: 90%;
    margin-left: 0px;
    top: 75vh;
  }

  .equality-topic-home {
    font-size: 100px;
    margin-left: 0px;
  }

  .equality-topic-home-spot {
    font-size: 100px;
    margin-left: 0px;
  }

  .equality-wow-gender-section-01 {
    display: flex;
    flex-direction: column;
  }

  .equality-wow-gender-section-01-right {
    width: 100%;
  }

  .equality-wow-gender-section-01-left img {
    position: relative;
    width: 60%;
  }

  .equality-wow-gender-section-01-right {
    width: 100%;
    justify-content: center;
  }

  .equality-wow-gender-section-01-right-contents {
    width: 80%;
    margin-right: 0;
  }

  .equality-stacked-bar-charts p {
    padding: 0;
    text-align: center;
  }

  .equality-card-width {
    margin-top: -50px;
    margin-left: -120px;
  }

  .equality-para-two {
    font-size: 70px;
    color: #d0f7ea00;
  }
}

@media (max-width: 400px) {
  .equality-topic-home-spot {
    font-size: 70px;
  }

  .equality-wow-gender-section-01-left img {
    width: 80%;
    margin-left: -41%;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
