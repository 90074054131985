@media (max-width: 870px) {
  .aa-pc {
    display: none;
  }
}

@media (min-width: 870px) {
  .aa-mobile {
    display: none;
  }
}

.conserve-waste-chart-01-whole{
  width: 60%;
}

.conserve-waste-chart-01-paragraph-two-column {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.conserve-waste-chart-01-paragraph-column-1-new{
  width: 5%;
  border-bottom: 1.4px solid #313893;
}

.conserve-waste-chart-01-paragraph-column-2-new {
  width: 65%;
  border-left: 1.4px solid #313893;
  border-bottom: 1.4px solid #313893;
  border-right: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 15px;
}

.conserve-waste-chart-01-paragraph-column-3-new{
  text-align: start;
  display: flex;
  justify-content: center;
  width: 30%;
}

.conserve-waste-chart-01-paragraph-column-4-new {
  width: 4.8%;
}

.conserve-waste-chart-01-paragraph-column-5-new {
  width: 65.2%;
  border-left: 1.4px solid #313893;
  border-right:none;
  display: flex;
  flex-direction: row;
  justify-content: space-around; 
}

.conserve-waste-chart-01-paragraph-column-6 {
  width: 30%;
}

@media (max-width:1192px) {
  .conserve-waste-chart-01-paragraph-column-2-new {
    padding-top: 0px;
  }
}


@media screen and (max-width: 870px) {
  .conserve-waste-chart-01-paragraph-two-column {
    flex-direction: column;
    height: auto;
    width: 100%;
  }

  .conserve-waste-chart-01-paragraph-column-2-new {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-right: none;
    border-left: 1.4px solid #313893;
  }

  .conserve-waste-chart-01-paragraph-column-3-new {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-left: 1.4px solid #313893;
    border-bottom: none;
  }

  .conserve-waste-chart-01-paragraph-column-4-new {
    width: 100%;
    text-align: center;
  }

  .conserve-waste-chart-01-paragraph-column-5-new {
    width: 100%;
    text-align: center;
  }

  .conserve-waste-chart-01-paragraph-column-6 {
    width: 100%;
    text-align: center;
  }

  .conserve-waste-chart-01-paragraph-column-1-new{
    width: 0%;

  }
}

.selected-text{
  opacity: 1;
}

.conserve-waste-chart-01-card-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.conserve-chart-heading{
  color: #313893;
  font-size: 16px;
  font-weight: 900;
  font-family: "GT America Trial Md";
  margin-bottom: 40px;
}

.conserve-waste-chart-01-card {
  flex: 1 1 auto;
  min-width: 180px;
  margin-bottom: 20px;
  overflow: hidden;
  margin: 2.5px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  justify-items: center;
  text-align: center;
  margin-bottom: 30px;
}

.conserve-waste-chart-01-number {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3%;
  margin-bottom: 15px;
}

.conserve-waste-chart-01-number.number-content-01{
  /* color: #00438f; */
  color: #000055;
}

.conserve-waste-chart-01-number.number-content-02{
  color: #000055;
}

.conserve-waste-chart-01-letter{
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3%;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.conserve-waste-chart-01-letter-icons{
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.conserve-waste-chart-01-letter-icon{
  opacity: 50%;
  /* color: #00438f; */
  color: #000055;
}

.selected-icon{
  opacity: 100%;
}

.conserve-waste-chart-01-letter.letter-content-01 h4{
  /* color: #00438f; */
  color: #000055;
  font-size: 18px;
}

.conserve-waste-chart-01-letter.letter-content-02 h4{
  color: #000055;
  font-size: 18px;
}

@media (max-width: 870px) {
  .conserve-waste-chart-01-paragraph-column-5-new{
    margin-top: -22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .conserve-waste-chart-01-letter{
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .conserve-waste-chart-01-whole{
    width: 100%;
  }
}