@font-face {
  font-family: "GT America Trial Cm Bl";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bl"),
    url("../../../fonts/GT-America-Compressed-Black-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Md";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Md"),
    url("../../../fonts/GT-America-Standard-Medium-Trial.woff") format("woff");
}

.new-energy-page-hightlight-chart-container {
  padding: 0px;
  background-color: #ffffff;
  overflow: hidden;
}

.new-energy-page-hightlight-chart-container {
  padding: 0px;
  background-color: #ffffff;
  overflow: hidden;
}

.new-energy-page-hightlight-header-contents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
  color: #313893;
}

@media (max-width: 620px) {
  .new-energy-page-hightlight-header-contents {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .new-energy-page-hightlight-header-contents {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.new-energy-page-hightlight-bolder-box-line {
  padding: 60px;
  border: 1px solid #c5f1f7;
  padding-bottom: 0px;
  padding-left: 80px;
}

.new-energy-page-hightlight-bolder-box-line-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "GT America Trial Md";
  color: #313893;
}

.new-energy-page-hightlight-bolder-box-line-content-left {
  width: 50%;
  padding: 80px;
  border: 1px solid #c5f1f7;
  border-top: 0;
}

.new-energy-page-hightlight-bolder-box-line-content-right {
  width: 50%;
  padding: 80px;
  border: 1px solid #c5f1f7;
  border-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
  border-top: 0;
}

@media (max-width: 620px) {
  .new-energy-chart-page-hightlight-bolder-box-line {
    border-right: 0px;
    border-left: 0px;
  }

  .new-energy-page-hightlight-bolder-box-line-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .new-energy-page-hightlight-bolder-box-line-content-left {
    width: 100%;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
  }

  .new-energy-page-hightlight-bolder-box-line-content-right {
    width: 100%;
    border-right: 0px;
    border-left: 0px;
  }
}

.topic-2nd {
  font-family: "GT America Trial Cm Bl";
  font-size: 33px;
  text-transform: uppercase;
  text-align: left;
  margin-left: 30px;
  color: #313893;
}

.new-conserve-chart-container {
  padding: 80px 100px;
  background-color: #c5f1f7;
  overflow: hidden;
}

.new-conserve-header-contents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
}

.new-conserve-header-content-left {
  width: 40%;
}

.new-conserve-header-content-right {
  width: 60%;
}

.new-conserve-header-contents * {
  color: #313893;
}

.new-conserve-header-content-left h1 {
  font-family: "GT America Trial Cm Bl";
  font-size: 66px;
  text-transform: uppercase;
}

.new-conserve-header-content-right p {
  font-family: "GT America Trial Md";
}

.new-conserve-bar-chart {
  width: 100%;
  margin-bottom: 50px;
}

.new-conserve-pie-charts {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 50px;
  width: 100%;
}

.chart-two-column {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.chart-column-1 {
  width: 50%;
  padding: 10px;
}

.chart-column-2 {
  width: 50%;
  padding: 10px;
}

.c-1-width {
  width: 60%;
}

.chart-para-p {
  text-align: start;
  color: #313893;
}

@media screen and (max-width: 1111px) {
  .chart-two-column {
    flex-direction: column;
  }

  .chart-column-1,
  .chart-column-2 {
    width: 100%;
  }

  .c-1-width {
    width: 100%;
  }
}

.chart-para-two-column {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.chart-para-column-1 {
  width: 30%;
  padding: 10px;
}

.chart-para-column-2 {
  width: 70%;
  padding: 10px;
}

@media screen and (max-width: 1111px) {
  .chart-para-two-column {
    flex-direction: column;
  }

  .chart-para-column-1,
  .chart-para-column-2 {
    width: 100%;
  }
}

/*----------------------------------- Media Query--------------------------------------------- */
@media (max-width: 1115px) {
  .new-conserve-header-content-left h1 {
    font-size: 46px;
  }

  .new-conserve-pie-charts {
    justify-content: space-between;
    padding: 0;
  }
}

@media (max-width: 920px) {
  .new-conserve-header-content-left h1 {
    font-size: 36px;
  }

  .new-conserve-pie-charts {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 800px) {
  .new-conserve-chart-container {
    padding: 80px 60px;
  }
}

@media (max-width: 700px) {
  .new-conserve-chart-container {
    padding: 80px 30px;
  }
}

@media (max-width: 620px) {
  .new-conserve-header-contents {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .new-conserve-header-content-left {
    width: 100%;
    margin-bottom: 30px;
  }

  .new-conserve-header-content-right {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .new-conserve-header-contents {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .new-conserve-header-content-left {
    width: 100%;
    margin-bottom: 30px;
  }

  .new-conserve-header-content-right {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .new-conserve-chart-container {
    padding: 80px 10px;
  }
}
