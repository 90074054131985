@font-face {
  font-family: "GT-America";
  src: url("../../../../fonts/GT-America-Compressed-Black-Trial.woff")
    format("truetype");
  font-style: normal;
}
/* font-family: 'GT-America', sans-serif; */

.empower-chart-4-paragraph-two-column {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 800px;
}

.empower-chart-4-paragraph-column-1 {
  width: 30%;
}

.empower-chart-4-paragraph-column-2 {
  width: 70%;
}

@media screen and (max-width: 900px) {
  .empower-chart-4-paragraph-two-column {
    flex-direction: column;
    height: auto;
    width: 100%;
  }

  .empower-chart-4-paragraph-column-1 {
    width: 100%;
    padding: 20px;
    text-align: center;
  }

  .empower-chart-4-paragraph-column-2 {
    width: 100%;
    padding: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .empower-chart-4-paragraph-column-2 {
    width: 100%;
  }
}

@media screen and (max-width: 1050px) {
  .pc {
    display: none;
  }

  .mobile {
    display: block;
  }
}

@media screen and (min-width: 1051px) {
  .mobile {
    display: none;
  }

  .pc {
    display: block;
  }
}

.empower-chart-4-highlights-main-topic {
  font-family: "GT-America", sans-serif;
  color: #313893;
  font-size: 60px;
  margin-bottom: 10px;
}

.empower-chart-4-img-gap-s {
  margin-bottom: 50px;
}

/* /// */
.chart-4-country-list {
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.chart-4-flag {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.chart-4-flag h3 {
  margin-top: 5px;
  font-size: 14px;
  text-decoration: none;
  /* color: #7077df9f; */
  color: #313893;
}

.chart-4-flag h3.selected {
  /* color: #101667; */
  color: #000055;
}

.chart-4-flag {
  width: auto;
  height: 50px;
  background-color: transparent;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

.chart-4-flag:hover img {
  filter: brightness(1);
}

.chart-4-flag.selected {
  /* border: 4px solid #ffffff; */
  border: none;
}

.chart-4-flag img {
  width: 80px;
  height: 50px;
  border-radius: 0%;
  object-fit: fill;
  filter: brightness(0.4);
  transition: filter 0.3s ease;
}

.chart-4-flag.selected img {
  filter: brightness(1);
}

.main-topic-chart-4-p {
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  color: rgb(49, 56, 147);
  font-family: "GT America Trial Md";
}

.topic-chart-4-p {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: rgb(49, 56, 147);
  font-family: "GT America Trial Md";
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .empower-chart-4-paragraph-two-column {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }
}

@media (max-width: 700px) {
  .e-pc {
    display: none;
  }
}

@media (min-width: 701px) {
  .e-mobile {
    display: none;
  }
}
