@font-face {
  font-family: "GT-America";
  src: url("../../../../../fonts/GT-America-Compressed-Black-Trial.woff")
    format("truetype");
  font-style: normal;
}
/* font-family: 'GT-America', sans-serif; */

.e-mobile.empower-equality-chart-03 {
  display: none;
}

.chart-1-paragraph-two-column {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 800px;
}

.chart-1-paragraph-two-column.empower-equality-chart-03{
  width: 100%;
}

.chart-1-paragraph-column-1 {
  width: 30%;
}

.chart-1-paragraph-column-2 {
  width: 70%;
}

@media screen and (max-width: 900px) {
  .chart-1-paragraph-two-column {
    flex-direction: column;
    height: auto;
    width: 100%;
  }

  .chart-1-paragraph-column-1 {
    width: 100%;
    padding: 20px;
    text-align: center;
  }

  .chart-1-paragraph-column-2 {
    width: 100%;
    padding: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .chart-1-paragraph-column-2 {
    width: 100%;
  }
}

@media screen and (max-width: 1050px) {
  .pc {
    display: none;
  }

  .mobile {
    display: block;
  }
}

@media screen and (min-width: 1051px) {
  .mobile {
    display: none;
  }

  .pc {
    display: block;
  }
}

.chart-1-highlights-main-topic {
  font-family: "GT-America", sans-serif;
  color: #313893;
  font-size: 60px;
  margin-bottom: 10px;
}

.chart-1-img-gap-s {
  margin-bottom: 50px;
}

/* /// */
.main-circles {
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.main-circles.empower-equality-chart-03 {
  flex-wrap: wrap;
}

.chart-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.chart-circle h3 {
  margin-top: 5px;
  font-size: 14px;
  text-decoration: none;
  color: #7077df9f;
}

.chart-circle h3.selected {
  color: #101667;
}

.chart-circle {
  width: auto;
  height: 50px;
  background-color: transparent;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

.main-circles.empower-equality-chart-03 .chart-circle{
  height: auto;
}

.chart-circle:hover img {
  filter: brightness(1);
}

.chart-circle.selected {
  /* border: 4px solid #ffffff; */
  border: none;
}

.chart-circle img {
  width: 78;
  height: 50px;
  border-radius: 0%;
  object-fit: cover;
  filter: brightness(0.4);
  transition: filter 0.3s ease;
}

.chart-circle.selected img {
  filter: brightness(1);
}

.main-topic-chart-p.empower-equality-chart-03 {
  text-align: center;
  font-size: 16px;
  font-weight: 900;
  color: rgb(49, 56, 147);
  font-family: "GT America Trial Md";
}

.topic-chart-p.empower-equality-chart-03 {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: rgb(49, 56, 147);
  font-family: "GT America Trial Md";
  margin-top: 8px;
}

@media screen and (max-width: 768px) {
  .chart-1-paragraph-two-column {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }
}

@media (max-width: 760px) {
  .e-pc.empower-equality-chart-03 {
    display: none;
  }
}

@media (max-width: 760px) {
  .e-mobile.empower-equality-chart-03 {
    display: block;
  }
}