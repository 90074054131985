@font-face {
  font-family: "GT-America-st-tr-bold";
  src: url("../../../../../fonts/GT-America-Standard-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}


@media (max-width: 950px) {
  .aa-pc.conserve-water-chart-01 {
    display: none;
  }
}

@media (max-width: 950px) {
  .aa-mobile.conserve-water-chart-01 {
    display: block;
  }
}

.conserve-water-chart-01-paragraph-two-column {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.conserve-water-chart-01-paragraph-column-1-new{
  width: 15%;
  border-bottom: 1.4px solid #313893;
}

.conserve-water-chart-01-paragraph-column-1-new h4{
  /* color: #834FC5; */
  color: #000055;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  font-family: "GT-America-st-tr-bold";
}

.conserve-water-chart-01-paragraph-column-2-new {
  width: 60%;
  border-left: 1.4px solid #313893;
  border-bottom: 1.4px solid #313893;
  border-right: none;
  font-family: "GT-America-st-tr-bold";
  padding-top: 6%;
}

.conserve-water-chart-01-paragraph-column-3-new{
  text-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  font-family: "GT-America-st-tr-bold";
}

.conserve-water-chart-01-paragraph-column-4-new {
  width: 15%;
}

.conserve-water-chart-01-paragraph-column-5-new {
  width: 60%;
  border-left: 1.4px solid #313893;
  border-right:none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 40px; 
  font-family: "GT-America-st-tr-bold";
}

.conserve-water-chart-01-paragraph-column-6 {
  width: 25%;
}

@media screen and (max-width: 950px) {
  .conserve-water-chart-01-paragraph-two-column {
    flex-direction: column;
    height: auto;
    width: 100%;
  }

  .conserve-water-chart-01-paragraph-column-2-new {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-right: none;
    border-left: 1.4px solid #313893;
  }

  .conserve-water-chart-01-paragraph-column-3-new {
    width: 100%;
    padding: 20px;
    text-align: center;
    border-left: 1.4px solid #313893;
    border-bottom: none;
  }

  .conserve-water-chart-01-paragraph-column-4-new {
    width: 100%;
    text-align: center;
  }

  .conserve-water-chart-01-paragraph-column-5-new {
    width: 100%;
    text-align: center;
  }

  .conserve-water-chart-01-paragraph-column-6 {
    width: 100%;
    text-align: center;
  }

  .conserve-water-chart-01-paragraph-column-1-new{
    border-bottom: none;
    width: 100%;
    padding: 20px;
    text-align: center;
    border-left: 1.4px solid #313893;
  }
}

.conserve-water-chart-01-text-box-1-opacity {
  background-color: transparent;
  width: 100%;
  /* color: #091294; */
  color: #000055;
  font-size: 18px;
  font-weight: 700;
  opacity: 0.4;
  cursor: pointer;
}

.conserve-water-chart-01-text-box-2-opacity {
  opacity: 0.4;
  background-color: transparent;
  width: 100%;
  /* color: #e96b12ee; */
  color: #000055;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.conserve-water-chart-01-text-box-3-opacity {
  opacity: 0.4;
  background-color: transparent;
  width: 100%;
  /* color:#ee0505; */
  color: #000055;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.conserve-water-chart-01-text-box-4-opacity {
  opacity: 0.4;
  background-color: transparent;
  width: 100%;
  /* color: #1c7401; */
  color: #000055;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.conserve-water-chart-01-text-box-5-opacity {
  opacity: 0.4;
  background-color: transparent;
  width: 100%;
  /* color: #003775; */
  color: #000055;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.selected-text{
  opacity: 1;
}

.conserve-water-chart-01-card-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.conserve-chart-heading{
  color: #313893;
  font-size: 18px;
  font-weight: 900;
  font-family: "GT America Trial Md";
  margin-bottom: 70px;
}

.conserve-water-chart-01-card {
  flex: 1 1 auto;
  min-width: 110px;
  margin-bottom: 20px;
  overflow: hidden;
  margin: 2.5px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  justify-items: center;
  text-align: center;
}

.conserve-water-chart-01-number {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3%;
  margin-bottom: 15px;
}

.conserve-water-chart-01-number.number-content-01{
  /* color: #00438f; */
  color: #000055;
}

.conserve-water-chart-01-number.number-content-02{
  /* color: #006956; */
  color: #000055;
}

.conserve-water-chart-01-number.number-content-03{
  color: #000055;
}

.conserve-water-chart-01-letter{
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3%;
  margin-top: 15px;
}

.conserve-water-chart-01-letter.letter-content-01 h4{
  /* color: #00438f; */
  color: #000055;
  font-size: 16px;
}

.conserve-water-chart-01-letter.letter-content-02 h4{
  /* color: #006956; */
  color: #000055;
  font-size: 16px;
}

.conserve-water-chart-01-letter.letter-content-03 h4{
  color: #000055;
  font-size: 16px;
}

