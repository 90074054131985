@font-face {
  font-family: "GT-America-2";
  src: url("../../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}
/* font-family: 'GT-America-2', sans-serif; */

/* section-1 */
.energy-topic-home {
  font-size: 120px;
  color: #ffffff;
  margin-left: 80px;
  font-family: "GT-America-2", sans-serif;
}

.home-video {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}

.home-page-video {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
/* section-1 end */

.screen-page {
  width: 100%;
  overflow-x: hidden;
  overflow: hidden;
}

@media (max-width: 768px) {
  .screen-page {
    overflow-y: hidden;
  }
}

/* video topic */
.energy-video-text-box {
  height: 100px;
  text-align: start;
  z-index: 1;
  position: absolute;
  top: 40vh;
}

@media (max-width: 768px) {
  .energy-video-text-box {
    width: 90%;
    margin-left: 0px;
    top: 45vh;
  }

  .energy-topic-home {
    font-size: 150px;
    margin-left: 20px;
  }
}

@media (max-width: 600px) {
  .energy-video-text-box {
    width: 90%;
    margin-left: 0px;
    top: 75vh;
  }

  .energy-topic-home {
    font-size: 100px;
    margin-left: 0px;
  }
}

/* section-3 */
.energy-cards {
  position: relative;
  background-color: #f5f5f5a0;
}

.energy-para-box {
  margin-left: 28%;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 20px;
  background-color: #ffffff;
}

.energypage-para {
  padding-top: 100px;
  padding-bottom: 100px;
  width: 700px;
  text-align: start;
}

@media (max-width: 800px) {
  .energypage-para {
    width: 65%;
  }
}

.energy-card-width {
  overflow: hidden;
  position: relative;
  margin-top: -120px;
  width: 100%;
  z-index: 2;
  margin-left: -250px;
}

.energy-para-two {
  margin-left: -28.5%;
  font-size: 250px;
  color: #d2e1dd;
  font-family: "GT America Trial Cm Bl";
  z-index: 1;
}

@media (max-width: 768px) {
  .energy-card-width {
    margin-top: -70px;
    margin-left: -200px;
  }

  .energy-para-two {
    font-size: 150px;
  }
}

@media (max-width: 600px) {
  .energy-card-width {
    margin-top: -50px;
    margin-left: -120px;
  }

  .energy-para-two {
    font-size: 70px;
    color: #d0f7ea00;
  }
}
