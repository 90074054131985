.trust-card-container-1 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
}

.card {
  flex: 1 1 auto;
  overflow: hidden;
  margin: 2.5px;
  margin-bottom: 20px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  justify-items: center;
  text-align: center;
}

.card.support-wellbeing-chart-01-card{
  min-width: 110px;
}

.trust-chart-img-2 {
  max-width: 100%;
}

.support-welbeing-Sri_Lanka_2023 {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #000055;
}

.support-welbeing-Sri_Lanka_2022 {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #000055;
}

.chart-box.support-wellbeing-chart-01-chart-box{
  width: 100%;
}

@media screen and (max-width: 900px) {
  .chart-box {
    width: auto;
  }
}

.num-c {
  font-size: 16px;
}