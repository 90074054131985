@font-face {
  font-family: "GT-America-2";
  src: url("../../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT America Trial Cm Bd";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bd"),
    url("../../../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}

@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap");

/* section-1 */
.e-wellbeing-topic-home {
  font-size: 120px;
  color: #ffffff;
  margin-left: 80px;
  font-family: "GT-America-2", sans-serif;
}

.e-wellbeing-text-box {
  height: 100px;
  text-align: start;
  z-index: 1;
  position: absolute;
  top: 40vh;
}

.e-wellbeing-para-two {
  margin-left: -28.5%;
  font-size: 250px;
  color: #d2e1dd;
  font-family: "GT America Trial Cm Bd";
  z-index: 1;
}

@media (max-width: 768px) {
  .e-wellbeing-text-box {
    width: 90%;
    margin-left: 0px;
    top: 45vh;
  }

  .e-wellbeing-topic-home {
    font-size: 150px;
    margin-left: 20px;
  }
}

@media (max-width: 600px) {
  .e-wellbeing-text-box {
    width: 90%;
    margin-left: 0px;
    top: 75vh;
  }

  .e-wellbeing-topic-home {
    font-size: 100px;
    margin-left: 0px;
  }
}

@media (max-width: 425px) {

  .e-wellbeing-topic-home {
    font-size: 80px;
  }
}

@media (max-width: 335px) {

  .e-wellbeing-topic-home {
    font-size: 70px;
  }
}

@media (max-width: 300px) {

  .e-wellbeing-topic-home {
    font-size: 60px;
  }
}

@media (max-width: 255px) {

  .e-wellbeing-topic-home {
    font-size: 50px;
  }
}

/* section-2 */
.e-wellbeing-cards {
  position: relative;
  background-color: #f5f5f5a0;
}

.e-wellbeing-para-box {
  margin-left: 28%;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 20px;
  background-color: #ffffff;
}

.e-wellbeing-page-para {
  padding-top: 100px;
  padding-bottom: 100px;
  width: 700px;
  text-align: start;
}

@media (max-width: 800px) {
  .e-wellbeing-page-para {
    width: 65%;
  }
}

.e-wellbeing-card-width {
  overflow: hidden;
  position: relative;
  margin-top: -120px;
  width: 100%;
  z-index: 2;
  margin-left: -250px;
}

.e-wellbeing-para-two {
  margin-left: -28.5%;
  font-size: 250px;
  color: #d2e1dd;
  font-family: "GT America Trial Cm Bd";
  z-index: 1;
}

@media (max-width: 768px) {
  .e-wellbeing-card-width {
    margin-top: -70px;
    margin-left: -200px;
  }

  .e-wellbeing-para-two {
    font-size: 150px;
  }
}

@media (max-width: 600px) {
  .e-wellbeing-card-width {
    margin-top: -50px;
    margin-left: -120px;
  }

  .e-wellbeing-para-two {
    font-size: 70px;
    color: #d0f7ea00;
  }
}
/* section-2 end */

/* section-3 */
.wow-page-background{
  background-color: #323896;
}
.e-wellbeing-paragraph-section-1 {
  background-color: #323896;
  color: #ffffff;
  width: 100%;
  padding: 0px 30px 80px;
}

.e-wellbeing-paragraph-section-1-contents{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
}

.wellbeing-single-bar-chart-container{
  width: 50%;
  display: flex;
  align-items: center;
}

.e-wellbeing-paragraph-section-1-center {
  text-align: left;
  width: 50%;
  margin-bottom: 50px;
}

.e-wellbeing-paragraph-section-1-center p {
  margin-bottom: 20px;
  /* font-family: "Arial Narrow", Arial, sans-serif; */
  font-family: "GT America Trial Cn Lt";
  font-size: 20px;
}

.e-wellbeing-paragraph-section-1-center-yello-p {
  color: #fff;
  padding-left: 35px;
}

@media (max-width: 1260px) {
  .e-wellbeing-paragraph-section-1-center p {
    width: 100%;
  }
}

@media (max-width: 1100px) {
  .e-wellbeing-paragraph-section-1-contents{
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
  }

  .wellbeing-single-bar-chart-container{
    width: 100%;
  }
  
  .e-wellbeing-paragraph-section-1-center {
    width: 100%;
  }

}

@media (max-width: 1000px) {
  .e-wellbeing-paragraph-section-1{
    padding: 80px 50px;
  }
}
/* section-3 end */


@media (max-width:850px) {
  .e-wellbeing-paragraph-section-1{
    padding: 80px 0px;
  }
}

@media (max-width:780px) {
  .e-wellbeing-paragraph-section-1-center {
    padding: 0 50px;
  }
}

@media (max-width:667px) {
  .e-wellbeing-paragraph-section-1-contents{
    padding: 0px 20px;
  }
}

