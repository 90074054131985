@font-face {
  font-family: "GT-America-st-tr-bold";
  src: url("../../../../fonts/GT-America-Standard-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

.trust-card-container-1 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-family: "GT-America-st-tr-bold";
}

.card {
  flex: 1 1 auto;
  margin-bottom: 20px;
  overflow: hidden;
  margin: 2.5px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  justify-items: center;
  text-align: center;
}

.trust-chart-img-2 {
  max-width: 100%;
}

.chart-1-t-c {
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #000055;
}

.chart-2-t-c {
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #000055;
}

.sri_lanka-number-loader {
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #ffffff;
  z-index: 20;
}

.trust-para-5-female {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #00438f;
}

.chart-box.empower-wellbeing-chart-01 {
  width: 100%;
}

@media screen and (max-width: 900px) {
  .chart-box {
    width: auto;
  }
}

.num-c-2 {
  font-size: 30px;
}
