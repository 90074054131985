/* section-1 */
.wellbing-topic-home {
  font-size: 120px;
  color: #ffffff;
  margin-left: 80px;
  font-family: "GT-America-2", sans-serif;
}

.home-video {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}

.home-page-video {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.screen-page {
  width: 100%;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .screen-page {
    overflow-y: hidden;
  }
}

/* video topic */
.wellbing-text-box {
  height: 100px;
  text-align: start;
  z-index: 1;
  position: absolute;
  top: 40vh;
}

@media (max-width: 768px) {
  .wellbing-text-box {
    width: 90%;
    margin-left: 0px;
    top: 45vh;
  }

  .wellbing-topic-home {
    font-size: 150px;
    margin-left: 20px;
  }
}

@media (max-width: 600px) {
  .wellbing-text-box {
    width: 90%;
    margin-left: 0px;
    top: 55vh;
  }

  .wellbing-topic-home {
    font-size: 80px;
    margin-left: 0px;
  }
}
/* section-1 end */
