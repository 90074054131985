@font-face {
  font-family: "GT-America-2";
  src: url("../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}


/* section-1 */
.home-video {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}

.home-page-video {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

svg.squiggle {
  position: absolute;
  top: 960px;
  left: 200px;

  width: 30vw; 
  height: 155vw;
  z-index: 0;
}


svg.squiggle path {
  stroke-width: 0.2rem;
}

@media (max-width: 1300px) {
  svg.squiggle {
    display: none;
  }
}

/* section-1 end */

.screen-page {
  width: 100%;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .screen-page {
    overflow-y: hidden;
  }
}

/* video topic */
.video-text-box {
  height: 100px;
  text-align: start;
  z-index: 1;
  position: absolute;
  top: 50vh;
  font-family: "GT-America-2";
  left: 20px;
}

@media (max-width: 768px) {
  .video-text-box {
    width: 90%;
    margin-left: 90px;
  }
}

@media (max-width: 600px) {
  .video-text-box {
    width: 90%;
    margin-left: 20px;
  }
  .video-text-box {
    top: 40vh;
  }
}

.video-topic-home {
  color: #ffffff;
  font-size: 80px;
}