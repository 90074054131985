@font-face {
    font-family: 'GT America Trial Cm Bl';
    font-style: normal;
    font-weight: normal;
    src: local('GT America Trial Cm Bl'), url('../../fonts/GT-America-Compressed-Black-Trial.woff') format('woff');
}

@font-face {
    font-family: 'GT America Trial Md';
    font-style: normal;
    font-weight: normal;
    src: local('GT America Trial Md'), url('../../fonts/GT-America-Standard-Medium-Trial.woff') format('woff');
}

.new-empower-chart-container-1 {
  padding: 0px;
  background-color: #fff;
  overflow: hidden;
}

.empower-bolder-box-line {
  padding: 60px;
  border: 1px solid #c5f1f7;
  padding-bottom: 0px;
  padding-left: 80px;
}

.empower-bolder-box-line-4 {
  padding: 30px;
  border: 1px solid #c5f1f7;
}

.empower-bolder-box-line-2 {
  padding: 60px;
  border: 1px solid #c5f1f7;
  padding-top: 20px;
  padding-left: 80px;
  border-bottom: 0px;
}

.empower-bolder-box-line-3 {
  padding: 60px;
  border: 1px solid #c5f1f7;
  padding-top: 20px;
  padding-left: 80px;
  border-top: 0px;
}

.empower-chart-container{ 
    padding: 80px 100px;
}

.empower-chart-header-contents{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
}

.empower-chart-header-content-left{
    width: 40%;
}

.empower-chart-header-content-right{
    width: 60%;
}

.empower-chart-header-content-right h4{
    font-size: 20px;
}

.empower-chart-header-content-right-text-bottom{
    display: flex;
    align-items: end;
}

.empower-chart-topic-center {
    color: #313893;
}

.empower-chart-header-contents *{
    color: #313893;
}

.empower-chart-header-content-left h1{
    font-family: 'GT America Trial Cm Bl';
    font-size: 66px;
    text-transform: uppercase;
}

.empower-chart-header-content-right p{
    font-family: 'GT America Trial Md';
    margin: 0;
}

.empower-bar-chart{
    width: 100%;
    margin-bottom: 50px;
}

.empower-pie-charts{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 100px;
    margin-bottom: 50px;
    width: 100%;
}

.empower-pie-charts.empower-pie-and-bar-chart{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    margin-bottom: 50px;
    width: 100%;
}

.empower-pie-and-bar-chart{
    align-items: start;
}


/*----------------------------------- Media Query--------------------------------------------- */
@media (max-width:1260px) {
    .empower-pie-charts.empower-pie-and-bar-chart{
        flex-direction: column;
        align-items: center;
        gap: 50px;
        margin-bottom: 100px;
    }
    
}
@media (max-width:1115px) {
    .empower-chart-header-content-left h1{
        font-size: 46px;
    }

    .empower-pie-charts{
        justify-content: space-between;
        padding: 0;
    }

    .empower-pie-and-bar-chart{
        align-items: center;
    }
    
}

@media (max-width:920px) {
    .empower-chart-header-content-left h1{
        font-size: 36px;
    }

    .empower-pie-charts{
        flex-direction: column;
        gap: 20px;
    } 
}

@media (max-width:800px) {
    .empower-chart-container{
        padding: 80px 60px;
    }
}

@media (max-width:700px) {
    .empower-chart-container{
        padding: 80px 30px;
    }
}

@media (max-width:620px) {
    .empower-chart-header-contents{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .empower-chart-header-content-left{
        width: 100%;
        margin-bottom: 30px;
    }
    
    .empower-chart-header-content-right{
        width: 100%;
    }
}

@media (max-width:500px) {
    .empower-chart-header-contents{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .empower-chart-header-content-left{
        width: 100%;
        margin-bottom: 30px;
    }
    
    .empower-chart-header-content-right{
        width: 100%;
    }
}

@media (max-width:400px) {

    .new-conserve-chart-container{
        padding: 80px 10px;
    }
}


/* Border */
.new-equality-hightlight-bolder-box-line-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "GT America Trial Md";
    color: #313893;
  }
  
  .new-equality-hightlight-bolder-box-line-content-left {
    width: 50%;
    padding: 80px;
    border: 1px solid #c5f1f7;
    border-top: 0;
  }
  
  .new-equality-hightlight-bolder-box-line-content-right {
    width: 50%;
    padding: 80px;
    border: 1px solid #c5f1f7;
    border-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    border-top: 0;
  }
  
  @media (max-width: 620px) {
    .new-equality-hightlight-bolder-box-line-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .new-equality-hightlight-bolder-box-line-content-left {
      width: 100%;
      border-bottom: 0px;
      border-top: 1px solid #c5f1f7;
      padding-bottom: 0px;
    }
  
    .new-equality-hightlight-bolder-box-line-content-right {
      width: 100%;
      border-top: 1px solid #c5f1f7;
      padding-bottom: 0px;
    }
  }