@font-face {
  font-family: "GT-America-2";
  src: url("../../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT America Trial Cm Bd";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bd"),
    url("../../../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}
  
  /* font-family: 'GT-America-2', sans-serif; */

  
  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .greenArea {
    height: auto;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .greenArea {
    height: 800px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .greenArea {
    height: 450px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .greenArea {
    height: 350px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .greenArea {
    height: 350px;
  }
}

.greenArea {
  display: flex;
  align-items: center;
}

.energypage-green-para {
  padding: 60px;
  /* font-family: "Arial Narrow", Arial, sans-serif;   */
  color: rgb(255, 255, 255);
  position: relative;
}

.paragr {
  width: 100%;
  font-size: 20px;
  font-family: "GT America Trial Cn Lt";
}

.paragra {
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  font-family: "GT America Trial Cm Bd";
}

@media (max-width: 768px) {
  .greenArea {
    height: 590px;
  }
  .paragr {
    width: 100%;
    font-size: 22px;
  }
}

@media (max-width: 600px) {
  .greenArea {
    height: 1100px;
  }
}

