@font-face {
    font-family: "GT-America-2";
    src: url("../../../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "GT America Trial Cm Bd";
    font-style: normal;
    font-weight: normal;
    src: local("GT America Trial Cm Bd"),
    url("../../../../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
    font-family: "GT America Trial Cn Lt";
    font-style: normal;
    font-weight: normal;
    src: local("GT America Trial Cn Lt"),
    url("../../../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}

@font-face {
    font-family: 'GT America Trial Th';
    font-style: normal;
    font-weight: normal;
    src: local('GT America Trial Th'), url('../../../../fonts/GT-America-Standard-Thin-Trial.woff') format('woff');
    }

@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');


.spotlight-topic-home {
    font-size: 100px;
    color: #ffffff;
    margin-left: 80px;
    font-family: "GT-America-2", sans-serif;
    text-transform: uppercase;
    line-height: 100px;
}

.pace-section{
    font-size: 80px;
    line-height: 80px;
}

.home-page-background {
    position: relative;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.screen-page {
    width: 100%;
    overflow-x: hidden;
    overflow: hidden;
}


/* Spotlight Section */

.spotlight-text-box {
    height: 100px;
    text-align: start;
    z-index: 1;
    position: absolute;
    top: 30vh;
}

.spotlight-contents{
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
}

.spotlight-content-left{
    width: 50%;
    display: flex;
    flex-direction: row;
}

.spotlight-content-right-heading {
    margin-left: 0;
    margin-bottom: 0;
    font-size: 250px;
    line-height: 250px;
    color: #313893;
    opacity: 20%;
    font-family: "GT America Trial Cm Bl";
    z-index: 1;
}

.spotlight-content-right{
    width: 50%;
    padding: 100px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.spotlight-content-right-contents{
    width: 80%;
}

.spotlight-content-left-l{
    width: 50%;
    background-color: #313893;
    z-index: 99;
    display: flex;
    align-items: center;
    position: relative;
}

.spotlight-content-left-l img{
    position: absolute;
    width: 200%;
}

.career-PACE-spotlight-content-left-l-content{
    position: absolute;
    left: 35%;
    top: 40%;
    width: 140%;
    text-align: center;
    color: #ffffff;
    font-family: "GT America Trial Th";
    font-size: 18px;
    font-weight: bold;
}

.spotlight-content-left-r{
    width: 50%;
    background-color: #c4e9f9;
}

.spotlight-content-right-contents{
    font-family: "GT America Trial Th";
    color: #313893;
    font-weight: bold;
}

  
  /*---------------- Media Queiry ------------------*/
  
@media (max-width:1156px) {
    .spotlight-content-left-l-content{
        top: 40%;
        font-size: 15px;
    }
}

@media (max-width:1080px) {
    .spotlight-topic-home.pace-section{
        font-size: 80px;
        line-height: 90px;
    }
}

@media (max-width:1040px) {
    .spotlight-content-left-l-content{
        left: 50%;
        top: 43%;
        font-size: 13px;
        font-weight: bold;
    }

    .spotlight-content-right-heading {
        font-size: 220px;
        line-height: 220px;
    }

}

@media (max-width:900px) {
    .spotlight-contents{
        flex-direction: column;
    }

    .spotlight-content-left{
        width: 100%;
    }
    
    .spotlight-content-right-heading {
        font-size: 250px;
        line-height: 250px;
    }
    
    .spotlight-content-right{
        width: 100%;
        padding: 100px 100px;
    }
    
    .spotlight-content-right-contents{
        width: 100%;
    }

    .spotlight-content-left-l img{
        width: 150%;
        margin-left: 25%;
    }

    .spotlight-content-left-l-content{
        top: 37%;
        left: 51%;
        width: 95%;
        font-size: 18px;
    }

}


@media (max-width:880px) {
    .spotlight-topic-home.pace-section{
        font-size: 70px;
        line-height: 80px;
    }
}


@media (max-width:810px) {
    .spotlight-content-left-l-content{
        font-size: 17px;
    }
}

@media (max-width: 770px) {
    .spotlight-content-left-l-content{
        font-size: 15px;
    }

    .screen-page {
        overflow-y: hidden;
    }

    .spotlight-text-box.PACE-section {
        width: 90%;
        margin-left: 0px;
        top: 35vh;
    }

    .spotlight-topic-home.pace-section{
        font-size: 60px;
        line-height: 70px;
    }

    .spotlight-topic-home {
        font-size: 80px;
        line-height: 80px;
        margin-left: 20px;
    }

    .pace-section{
        font-size: 60px;
        line-height: 60px;
        margin-left: 20px;
    }
}

@media (max-width:675px) {
    .spotlight-content-left-l-content{
        font-size: 14px;
        top: 30%;
    }
}

@media (max-width:600px) {

    .spotlight-text-box {
        width: 90%;
        margin-left: 0px;
    }

    .spotlight-topic-home {
        font-size: 80px;
        line-height: 80px;
        margin-left: 0px;
    }

    .pace-section{
        font-size: 60px;
        line-height: 60px;
        margin-left: 0;
    }
}

@media (max-width:580px) {
    .spotlight-content-left-l img{
        width: 180%;
        margin-left: 10%;
    }

    .spotlight-content-right-heading {
        font-size: 200px;
        line-height: 200px;
    }

    .spotlight-content-right{
        width: 100%;
        padding: 100px 70px;
    }
}

@media (max-width:500px) {
    .spotlight-content-left-l img{
        width: 200%;
        margin-left: 0;
    }
}

@media (max-width:475px) {
    .spotlight-content-left-l-content{
        font-size: 13px;
    }

    .spotlight-content-right{
        width: 100%;
        padding: 80px 50px;
    }

    .spotlight-content-right-heading {
        font-size: 170px;
        line-height: 200px;
    }
    
}


@media (max-width:460px) {
    .spotlight-content-left-l-content{
        font-size: 12px;
        top: 30%;
    }

    .spotlight-topic-home.pace-section{
        font-size: 50px;
        line-height: 60px;
    }
}

@media (max-width:400px) {
    .spotlight-content-left-l-content{
        top: 28%;
    }
    
}

@media (max-width:377px) {
    .spotlight-content-left-l-content{
        top: 20%;
    }

    .spotlight-topic-home {
        font-size: 60px;
        line-height: 60px;
    }

    .pace-section{
        font-size: 50px;
        line-height: 50px;
    }
}

@media (max-width:360px) {
    .spotlight-content-left-l-content{
        font-size: 10px;
        top: 26%;
    }

    .spotlight-content-right{
        padding: 50px 30px;
    }
    
}

@media (max-width:345px) {
    
    .spotlight-content-right-heading {
        font-size: 140px;
        line-height: 180px;
    }

}

@media (max-width:320px) {
    .spotlight-content-left-l-content{
        top: 20%;
    }
    
}

@media (max-width:290px) {
    
    .spotlight-content-right-heading {
        font-size: 100px;
        line-height: 150px;
    }
    
}

@media (max-width:280px) {
    
    .spotlight-topic-home {
        font-size: 40px;
        line-height: 40px;
    }
}



/* Text and Image Queries */

@media (max-width:1156px) {
    .career-PACE-spotlight-content-left-l-content{
        top: 43%;
        font-size: 15px;
    }
}

@media (max-width:951px) {
    .career-PACE-spotlight-content-left-l-content{
        top: 44%;
        font-size: 13px;
    }
}

@media (max-width:900px) {

    .spotlight-content-left{
        width: 100%;
        height: 350px;
    }

    .spotlight-content-left-l.career-PACE-spotlight-left-l img{
        width: 100%;
        margin-left:50%;
    }

    .career-PACE-spotlight-content-left-l-content{
        position: relative;
        left: 62%;
        top: 3%;
        width: 80%;
        font-size: 12px;
    }

}

@media (max-width:650px) {
    .spotlight-content-left-l.career-PACE-spotlight-left-l img{
        width: 150%;
        margin-left:25%;
    }
}

@media (max-width:580px) {
    .spotlight-content-left-l.career-PACE-spotlight-left-l img{
        width: 180%;
        margin-left:10%;
    }

    .spotlight-content-left{
        width: 100%;
        height: 450px;
    }
}

@media (max-width:500px) {
    .spotlight-content-left-l.career-PACE-spotlight-left-l img{
        width: 195%;
        margin-left:2.5%;
    }

    .career-PACE-spotlight-content-left-l-content{
        position: relative;
        left: 50%;
        top: 3%;
        width: 150%;
        font-size: 12px;
    }
}

@media (max-width:460px) {
    .career-PACE-spotlight-content-left-l-content{
        font-size: 10px;
    }
}

@media (max-width:377px) {
    .career-PACE-spotlight-content-left-l-content{
        font-size: 9px;
    }
}