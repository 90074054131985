@font-face {
  font-family: "GT America Trial Cm Th";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Th"),
    url("../../fonts/GT-America-Compressed-Thin-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cm Bd";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bd"),
    url("../../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}

.sus-rep-conatiner {
  display: flex;
  justify-content: flex-start;
  padding: 100px 0;
}

.sus-rep-main-contents {
  display: flex;
  flex-direction: row;
  gap: 6%;
}

.sus-rep-left-contents {
  position: relative;
  padding: 60px 0;
  display: flex;
  align-items: center;
  width: 50%;
}

.sus-rep-front-image {
  height: 100%;
  width: 100%;
  z-index: -5;
}

.sus-rep-front-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.sus-rep-back {
  background-color: #f0f0f1;
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -10;
}

.sus-rep-right-contents {
  width: 35%;
  height: 100%;
  padding-top: 80px;
}

.sus-rep-right-content h3 {
  color: #313893;
  font-family: "GT America Trial Cm Th", sans-serif;
  font-size: 35px;
}

.sus-rep-right-content h1 {
  color: #313893;
  font-family: "GT America Trial Cm Bd", sans-serif;
  font-size: 70px;
  line-height: 70px;
}

.sus-rep-right-content p {
  font-family: "GT America Trial Cn Lt", sans-serif;
  font-size: 19px;
  line-height: 25px;
}

/* .sus-rep-right-content button {
  background-color: #313893;
  color: #ffffff;
  padding: 12px;
  padding-right: 35px;
  padding-left: 35px;
  font-size: 16px;
  line-height: 14px;
  font-weight: 600;
  border-radius: 50px;
  text-decoration: none;
  border: 2px solid #313893;
  font-family: "GT America Trial Cn Lt", sans-serif;
} */

/* .sus-rep-right-content-icon {
  transition: 0.5s ease all;
} */

/* .sus-rep-right-content button:hover .sus-rep-right-content-icon {
  color: #165541;
  transform: translateX(-198px);
} */

.target_button {
  background-color: #313893;
  color: #ffffff;
  padding: 12px;
  padding-right: 35px;
  padding-left: 35px;
  font-size: 16px;
  line-height: 14px;
  font-weight: 600;
  border-radius: 50px;
  text-decoration: none;
  border: 2px solid #313893;
  font-family: "GT America Trial Cn Lt", sans-serif;
}

.target_button:hover {
  background-color: #ffffff;
  color: #313893;
  text-decoration: none;
  border: 2px solid #313893;
}

/* .sus-rep-right-content button:hover {
  color: #165541;
  transform: translateX(10px);
} */

@media (max-width: 1017px) {
  .sus-rep-right-content h1 {
    font-size: 60px;
  }
}

@media (max-width: 900px) {
  .sus-rep-right-contents {
    padding-top: 30px;
  }
}

@media (max-width: 800px) {
  .sus-rep-main-contents {
    flex-direction: column;
    gap: 6%;
  }
  .sus-rep-left-contents {
    width: 80%;
  }

  .sus-rep-right-contents {
    width: 100%;
    height: 100%;
    padding: 0 20px;
  }
}

@media (max-width: 600px) {
  .sus-rep-left-contents {
    width: 90%;
  }

  .sus-rep-right-content p {
    font-size: 30px;
  }

  .sus-rep-right-content button {
    font-size: 20px;
  }
}

@media (max-width: 400px) {
  .sus-rep-right-content h3 {
    font-size: 28px;
  }

  .sus-rep-right-content h1 {
    font-size: 50px;
  }

  .sus-rep-right-content p {
    font-size: 20px;
  }
}

@media (max-width: 350px) {
  .sus-rep-right-content h1 {
    font-size: 40px;
    line-height: 45px;
  }

  .sus-rep-right-content button {
    font-size: 20px;
  }

  .sus-rep-right-content button:hover .sus-rep-right-content-icon {
    color: #165541;
    transform: translateX(-190px);
  }
}

@media (max-width: 285px) {
  .sus-rep-right-content h3 {
    font-size: 22px;
  }

  .sus-rep-right-content h1 {
    font-size: 30px;
    line-height: 35px;
  }
}
