@font-face {
  font-family: "GT-America-2";
  src: url("../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}
.trust-section-paragraphs {
  /* font-family: "Arial Narrow", Arial, sans-serif; */
  font-family: "GT America Trial Cn Lt";
  font-size: 20px;
  color: rgb(255, 255, 255);
  position: relative;
}

.trust-section-blue-area {
  padding: 85px 60px;
}

.trust-section-blue-area {
  display: flex;
  align-items: center;
}

.trust-section-paragraphs h1 {
  font-family: "GT America Trial Cm Bd";
}

.trust-section-paragraph {
  width: 100%;
}
