.career-male-2-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 71px;
  position: relative; /* Add position relative */
}

.career-male-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #00438f85; */
  background-color: #313893;
}

.career-male-2-image {
  position: relative; /* Set position relative */
  max-width: 100%;
  height: 100%;
  z-index: 1; /* Set z-index to bring the image above the water */
  object-fit: cover; /* Ensure the image covers the entire container */
}

.career-male-2-water {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  height: 0; /* Initialize height to 0 */
  width: 100%;
  /* background-color: #00438f; */
  background-color: #000055;
  transition: height 0.5s ease; /* Smooth transition for height change */
}
