@font-face {
    font-family: 'GT America Trial Cm Bl';
    font-style: normal;
    font-weight: normal;
    src: local('GT America Trial Cm Bl'), url('../../../fonts/GT-America-Compressed-Black-Trial.woff') format('woff');
}

@font-face {
    font-family: 'GT America Trial Md';
    font-style: normal;
    font-weight: normal;
    src: local('GT America Trial Md'), url('../../../fonts/GT-America-Standard-Medium-Trial.woff') format('woff');
}

@font-face {
  font-family: "GT-America";
  src: url("../../../fonts/GT-America-Compressed-Black-Trial.woff")
    format("truetype");
  font-style: normal;
}

/* font-family: 'GT-America', sans-serif; */
.main-topic-career-chart-p-n {
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  color: rgb(49, 56, 147);
  font-family: "GT America Trial Md";
}

.career-development-chart-container-1{
    padding: 0;
    background-color: #fff;
}

.career-development-header-contents{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
}

.career-development-header-content-left-01{
    width: 100%;
}

.career-development-header-content-right{
    width: 60%;
}

.career-development-header-contents *{
    color: #313893;
}

.career-development-header-content-left-01 h1{
    font-family: 'GT America Trial Cm Bl';
    font-size: 66px;
    text-transform: uppercase;
}

.career-development-header-content-right p{
    font-family: 'GT America Trial Md';
}

.career-development-bar-chart{
    width: 100%;
    margin-bottom: 50px;
}

.new-conserve-pie-charts{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 100px;
    margin-bottom: 50px;
    width: 100%;
}


/*----------------------------------- Media Query--------------------------------------------- */
@media (max-width:1115px) {
    .career-development-header-content-left-01 h1{
        font-size: 46px;
    }

    .new-conserve-pie-charts{
        justify-content: space-between;
        padding: 0;
    }
    
}

@media (max-width:920px) {
    .career-development-header-content-left-01 h1{
        font-size: 36px;
    }

    .new-conserve-pie-charts{
        flex-direction: column;
        gap: 20px;
    } 
}

@media (max-width:800px) {
    .career-development-chart-container{
        padding: 80px 60px;
    }
}

@media (max-width:700px) {
    .career-development-chart-container{
        padding: 80px 30px;
    }
}

@media (max-width:620px) {
    .career-development-header-contents{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .career-development-header-content-left-01{
        width: 100%;
        margin-bottom: 30px;
    }
    
    .career-development-header-content-right{
        width: 100%;
    }
}

@media (max-width:500px) {
    .career-development-header-contents{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .career-development-header-content-left-01{
        width: 100%;
        margin-bottom: 30px;
    }
    
    .career-development-header-content-right{
        width: 100%;
    }
}


/* Borders */

.empower-career-dev-bolder-box-line-01 {
    padding: 60px 60px 60px 80px;
    border: 1px solid #c5f1f7;
    color: #313893;
}

.conserve-empower-bolder-box-line-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.conserve-empower-bolder-box-line-content-left {
    width: 50%;
    padding: 80px;
    border: 1px solid #c5f1f7;
    color: #313893;
    font-family: 'GT America Trial Md';
}

.conserve-empower-bolder-box-line-content-left h4{
    font-size: 20px;
}

.conserve-empower-bolder-box-line-content-right {
    width: 50%;
    padding: 80px 15px;
    border: 1px solid #c5f1f7;
    border-left: 0px;
    font-family: 'GT America Trial Md';
}

@media (max-width:700px) {
    .conserve-empower-bolder-box-line-content-right {
        padding: 80px 50px;
    }
}

@media (max-width: 620px) {
    .conserve-empower-bolder-box-line-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .conserve-empower-bolder-box-line-content-left {
        width: 100%;
        border-bottom: 0px;
    }

    .conserve-empower-bolder-box-line-content-right {
        width: 100%;
        padding: 80px;
    }
}