.conserve-waste-chart-02-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 90px;
  position: relative;
}

.conserve-waste-chart-02-image {
  position: relative;
  max-width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
}

.conserve-waste-chart-02-container-content-01 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #b8010185; */
  background-color: #313893;
}

.conserve-waste-chart-02-container-content-02 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #e05e0085; */
  background-color: #313893;
}

.conserve-waste-chart-02-container-content-03 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #00005585; */
  background-color: #313893;
}

.conserve-waste-chart-02-container-content-04 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(17, 107, 79, 0.6); */
  background-color: #313893;
}

.conserve-waste-chart-02-image-water-effect-01 {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  /* background-color: #b80101; */
  background-color: #000055;
  opacity: 1;
  transition: height 0.5s ease;
}

.conserve-waste-chart-02-image-water-effect-02 {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  /* background-color: #e05e00; */
  background-color: #000055;
  transition: height 0.5s ease;
}

.conserve-waste-chart-02-image-water-effect-03 {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: #000055;
  transition: height 0.5s ease;
}

.conserve-waste-chart-02-image-water-effect-04 {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  /* background-color: rgb(17, 107, 79); */
  background-color: #000055;
  transition: height 0.5s ease;
}