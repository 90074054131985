.flip-button-image-1 {
  border-radius: 10px;
  height: 350px;
  width: 250px;
  background-image: url("../../assets/new_conserve_energy.png");
  background-size: cover;
}

.flip-button-image-1:hover {
  background-image: url("../../assets/p8.png");
}

.flip-button-image-2 {
  border-radius: 10px;
  height: 350px;
  width: 250px;
  background-image: url("../../assets/new_conserve_water_02.webp");
  background-size: cover;
}

.flip-button-image-2:hover {
  background-image: url("../../assets/happy-people-11934050.webp");
}

.flip-button-image-3 {
  border-radius: 10px;
  height: 350px;
  width: 250px;
  background-image: url("../../assets/new_conserve_chemical_02.webp");
  background-size: cover;
}

.flip-button-image-3:hover {
  background-image: url("../../assets/c8.webp");
}

.flip-button-image-4 {
  border-radius: 10px;
  height: 350px;
  width: 250px;
  background-image: url("../../assets/new_conserve_waste_02.webp");
  background-size: cover;
}

.flip-button-image-4:hover {
  background-image: url("../../assets/new_conserve_waste_03.webp");
}
