@font-face {
  font-family: "GT America Trial Md";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Md"),
    url("../../../fonts/GT-America-Standard-Medium-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Lt"),
    url("../../../fonts/GT-America-Standard-Light-Trial.woff") format("woff");
}

.new-2-energy-page-hightlight-chart-container {
  padding: 0px;
  background-color: #ffffff;
  overflow: hidden;
}

.new-2-energy-page-hightlight-header-contents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
  color: #313893;
}

@media (max-width: 620px) {
  .new-2-energy-page-hightlight-header-contents {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .new-2-energy-page-hightlight-header-contents {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.new-2-energy-page-hightlight-bolder-box-line {
  padding: 60px;
  border: 1px solid #c5f1f7;
  padding-bottom: 0px;
  padding-left: 80px;
}

.new-2-energy-page-hightlight-bolder-box-line-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "GT America Trial Md";
  color: #313893;
}

.new-2-energy-page-hightlight-bolder-box-line-content-left {
  width: 50%;
  padding: 80px;
  border: 1px solid #c5f1f7;
  border-top: 0;
}

.new-2-energy-page-hightlight-bolder-box-line-content-left h4{
  font-size: 20px;
}



.new-2-energy-page-hightlight-bolder-box-line-content-right {
  width: 50%;
  padding: 80px;
  border: 1px solid #c5f1f7;
  border-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
  border-top: 0;
}

@media (max-width: 620px) {
  .new-2-energy-chart-page-hightlight-bolder-box-line {
    border-right: 0px;
    border-left: 0px;
  }

  .new-2-energy-page-hightlight-bolder-box-line-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .new-2-energy-page-hightlight-bolder-box-line-content-left {
    width: 100%;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
  }

  .new-2-energy-page-hightlight-bolder-box-line-content-right {
    width: 100%;
    border-right: 0px;
    border-left: 0px;
  }
}

.energy-chart-section-2 {
  background-color: #b7f9e8;
  padding: 100px 0;
}

.energy-chart-section-2-container {
  width: 80%;
}

.Header {
  margin-bottom: 20px;
  font-size: 22px;
}

.Header h6 {
  color: #313893;
  font-family: "GT America Trial Md";
  font-weight: bold;
}

.energy-chart-section-2-charts {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 50px;
}

.bar {
  width: 55%;
  height: 400px;
}

.donut {
  width: 45%;
  height: 400px;
}

@media (max-width: 768px) {
  .donut {
    margin-top: 50px;
    height: 300px;
  }
}

.enery-chart-section-2-contents {
  margin-left: 42%;
  text-align: left;
  color: #313893;
}

.enery-chart-section-2-contents p {
  font-family: "GT America Trial Lt";
}

@media (max-width: 1030px) {
  .bar {
    width: 50%;
  }

  .donut {
    width: 50%;
  }
}

@media (max-width: 840px) {
  .energy-chart-section-2-charts {
    align-items: center;
    flex-direction: column;
  }

  .bar {
    width: 80%;
  }

  .donut {
    width: 80%;
  }

  .enery-chart-section-2-contents {
    margin-left: 0%;
    text-align: center;
  }
}

@media (max-width: 755px) {
  .donut {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .bar {
    width: 100%;
  }
}

@media (max-width: 363px) {
  .energy-chart-section-2-container {
    width: 90%;
  }
}
