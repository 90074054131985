.employees-chart-topic {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: rgb(49, 56, 147);
  font-family: "GT America Trial Md";
}

.card-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card {
  flex: 1 1 auto;
  margin-bottom: 20px;
  overflow: hidden;
  margin: 2.5px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  justify-items: center;
  justify-content: center;
}

.chart-img {
  max-width: 100%;
}

.chart-img-container {
  position: relative;
}

.card-overlay {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.number-loader {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #fff;
}

@media (max-width: 600px) {
  .chart-pc {
    display: none;
  }
}

@media (min-width: 601px) {
  .chart-mobile {
    display: none;
  }
}
