.conserve-energy-chart-04-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 90px;
  position: relative;
}

.conserve-energy-chart-04-image {
  position: relative;
  max-width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
}

.conserve-energy-chart-04-container-content-01 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #67007c85; */
  background-color: #313893;
}

.conserve-energy-chart-04-container-content-02 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 99, 67, 0.6); */
  background-color: #313893;
}

.conserve-energy-chart-04-container-content-03 {
  position: absolute;
  top: 0;
  left: 0;
  width: 99%;
  height: 100%;
  /* background-color: #00438f85; */
  background-color: #313893;
}

.conserve-energy-chart-04-image-water-effect-01 {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  /* background-color: #67007c; */
  background-color: #000055;
  opacity: 1;
  transition: height 0.5s ease;
}

.conserve-energy-chart-04-image-water-effect-02 {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  /* background-color: rgb(0, 71, 49); */
  background-color: #000055;
  transition: height 0.5s ease;
}

.conserve-energy-chart-04-image-water-effect-03 {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  /* background-color: #00438f; */
  background-color: #000055;
  transition: height 0.5s ease;
}