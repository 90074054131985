.bottle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100px;
  position: relative; /* Add position relative */
}

.bottle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 255, 255, 0.275);
}

.bottle-image {
  position: relative; /* Set position relative */
  width: 100%;
  height: 100%;
  z-index: 1; /* Set z-index to bring the image above the water */
  object-fit: cover; /* Ensure the image covers the entire container */
}

.water {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  height: 0; /* Initialize height to 0 */
  width: 100%;
  background-color: blue;
  transition: height 0.5s ease; /* Smooth transition for height change */
}

.bottle-icon {
  position: absolute;
  bottom: 5px; /* Adjust icon position as needed */
  left: 50%; /* Adjust icon position as needed */
  transform: translateX(-50%);
  font-size: 30px; /* Adjust icon size as needed */
}
