@font-face {
  font-family: "GT America Trial Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Lt"),
    url("../../../fonts/GT-America-Standard-Light-Trial.woff") format("woff");
}

.career-card {
  padding: 20px;
  font-family: "GT America Trial Lt";
  border-radius: 20px;
  text-align: start;
  width: 90%;
  height: 280px;
}

.career-slider {
  width: 75%;
}

.career-card-title {
  padding: 20px;
  text-align: end;
  margin-top: -60px;
  margin-bottom: -20px;
  font-weight: bold;
}

.career-card-description {
  padding: 20px;
}

.career-card-circle {
  margin-top: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.career-card-circle p {
  margin: 0;
  font-size: 13px;
}

/* New POPUP */

.career-card-circle-container{
  position: relative;
}

.hover-area{
  visibility: hidden;
  width: 80%;
  text-align: center;
  padding: 10px 5px;
  border-radius: 6px;
  position: absolute;
  top:-8%;
  left: 25%;
}

.hover-area.hover-area-empower-career{
  left: 23%;
}

.tooltip-texts{
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
}

.career-card-circle:hover + .hover-area{
  visibility: visible;
}

.hover-area-arrow{
  position: absolute;
  rotate: 180deg;
  left: -15px;
  top: 15%;
}

@media (max-width: 990px) {
  .career-slider {
    width: 90%;
  }
}

@media (max-width: 850px) {
  .career-slider {
    width: 100%;
  }
}

@media (max-width: 760px) {
  .career-card {
    width: 85%;
  }
}


/* Media Query */

@media (max-width:1100px) {
  .career-card {
    height: 280px;
  }
}

@media (max-width:742px) {
  .career-card {
    height: 320px;
  }
}

@media (max-width:600px) {
  .career-card {
    height: 280px;
  }
}

@media (max-width:360px) {
  .career-card {
    height: 320px;
  }
}