.company-logo {
  margin-top: 30px;
  height: 80px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

@media (max-width: 768px) {
  .screen-pc {
    display: none;
  }
}

@media (min-width: 769px) {
  .screen-mobile {
    display: none;
  }
}
