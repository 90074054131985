@font-face {
  font-family: "GT America Trial Cm Bl";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bl"),
    url("../../fonts/GT-America-Compressed-Black-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Lt"),
    url("../../fonts/GT-America-Standard-Light-Trial.woff") format("woff");
}

.trust-feature-stories-container{
  border: 2px solid #313893;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.feature-main-topic {
  color: #313893;
  font-size: 50px;
  font-family: "GT America Trial Cm Bl";
}

.feature-sub-topic {
  color: #313893;
  font-size: 33px;
  font-family: "GT America Trial Cm Bl";
}

.feature-main-topic-paras{
  font-family: "GT America Trial Lt";
}

.trust-feature-story-underline {
  width: 100%;
  height: 2px;
  background-color: #212529;
}

.trust-feature-stries-container {
  background-color: #ffffff;
  padding: 80px 70px 80px;
}

.trust-feature-story-topic h1 {
  font-family: "GT America Trial Cm Bl";
  font-size: 66px;
}

/*------------------------------------ Media Query --------------------------------*/
@media (max-width: 1000px) {
  .trust-feature-stries-container {
    padding: 80px 70px 10px;
  }
}

@media (max-width: 600px) {
  .trust-feature-stries-container {
    padding: 20px;
  }

  .trust-feature-story-topic h1 {
    font-size: 50px;
  }
}

/* part 2 */
.trust-feature-story-para {
  width: 90%;
  text-align: start;
}

.feature-two-column {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.feature-column-1 {
  width: 60%;
  padding: 20px;
}

.feature-column-2 {
  width: 40%;
  padding: 20px;
}

.feature-column-3 {
  width: 40%;
  padding: 20px;
}

.feature-column-4 {
  width: 60%;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .feature-two-column {
    flex-direction: column;
  }

  .feature-column-1,
  .feature-column-2,
  .feature-column-3,
  .feature-column-4 {
    width: 100%;
  }

  .trust-feature-story-para {
    width: 100%;
    text-align: start;
  }
}

.feature-stories-img {
  width: 100%;
  margin-top: 9px;
}
