@font-face {
  font-family: "GT-America-2";
  src: url("../../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT America Trial Cm Bd";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bd"),
    url("../../../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}

@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap");

/* section-1 */
.environment-spotlight-topic-home {
  font-size: 120px;
  color: #ffffff;
  margin-left: 80px;
  font-family: "GT-America-2", sans-serif;
}

.environment-spotlight-topic-home-spot {
  font-size: 120px;
  color: #ffffff;
  margin-left: 80px;
  font-family: "GT-America-2", sans-serif;
}

.environment-spotlight-background {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.environment-spotlight-text-box-2 {
  height: 100px;
  text-align: start;
  z-index: 1;
  position: absolute;
  top: 45vh;
}

@media (max-width: 768px) {
  .environment-spotlight-text-box-2 {
    width: 90%;
    margin-left: 0px;
    top: 45vh;
  }

  .environment-spotlight-topic-home-spot {
    font-size: 150px;
    margin-left: 20px;
  }
}

@media (max-width: 600px) {
  .environment-spotlight-text-box-2 {
    width: 90%;
    margin-left: 0px;
    top: 75vh;
  }

  .environment-spotlight-topic-home-spot {
    font-size: 100px;
    margin-left: 0px;
  }
}

@media (max-width: 400px) {
  .environment-spotlight-topic-home-spot {
    font-size: 70px;
  }
}
/* section-1 end */

/* section-2 */
.environment-spotlight-wow-gender-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #313893;
  padding-top: 20px;
}
.environment-spotlight-wow-gender-section-left {
  width: 30%;
  background-color: #313893;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.environment-spotlight-wow-gender-section-left img {
  width: 150%;
}

.environment-spotlight-wow-gender-section-left-01 img {
  position: absolute;
  left: 25%;
  width: 150%;
  border-radius: 30px;
  object-fit: cover;
  border: 10px solid white;
}

.environment-spotlight-wow-gender-section-right {
  width: 70%;
  background-color: #c4e9f9;
  display: flex;
  justify-content: end;
  align-items: center;
}

.environment-spotlight-wow-gender-section-right-01 {
  padding: 130px 0;
}

.environment-spotlight-wow-gender-section-right-contents {
  width: 45%;
  margin-right: 8%;
}

.environment-spotlight-wow-gender-section-right-contents h1 {
  text-transform: uppercase;
  color: #313893;
  font-family: "GT America Trial Cm Bd", sans-serif;
  font-size: 70px;
  line-height: 70px;
}

.environment-spotlight-wow-gender-section-right-contents h2 {
  text-transform: uppercase;
  color: #313893;
  font-family: "GT America Trial Cm Bd", sans-serif;
  font-size: 45px;
  line-height: 45px;
}

.environment-spotlight-wow-gender-section-right-contents p {
  font-family: "GT America Trial Cn Lt", sans-serif;
}

.environment-spotlight-wow-gender-section-right-contents button {
  position: relative;
  align-items: center;
  padding-left: 0;
  display: flex;
  border: none;
  outline: none;
  background-color: transparent;
  font-weight: 500;
  align-self: flex-start;
  margin-top: auto;
  margin-bottom: 10px;
  transition: 0.5s ease all;
}

.environment-spotlight-wow-gender-icon {
  transition: 0.5s ease all;
}

.environment-spotlight-wow-gender-section-right-contents
  button:hover
  .environment-spotlight-wow-gender-icon {
  color: #313893;
  transform: translateX(-165px);
}

.environment-spotlight-wow-gender-section-right-contents button:hover {
  color: #313893;
  transform: translateX(30px);
}

/* Media Query */

@media (max-width: 600px) {
  .environment-spotlight-wow-gender-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20px 0;
  }

  .environment-spotlight-wow-gender-section-left {
    width: 100%;
    padding: 30px 0;
  }

  .environment-spotlight-wow-gender-section-right {
    width: 100%;
    justify-content: start;
    padding: 100px 70px;
  }

  .environment-spotlight-wow-gender-section-left img {
    position: relative;
    max-width: 90%;
    margin-left: 0%;
    margin-bottom: 18px;
    left: 0%;
  }

  .environment-spotlight-wow-gender-section-right-contents {
    width: 90%;
    margin-right: 8%;
  }
}

@media (max-width: 370px) {
  .environment-spotlight-wow-gender-section-right {
    width: 100%;
    justify-content: start;
    padding: 100px 30px;
  }

  .environment-spotlight-wow-gender-section-right-contents {
    width: 100%;
    margin-right: 2%;
  }
}

@media (max-width: 768px) {
  .environment-spotlight-topic-home {
    font-size: 150px;
    margin-left: 20px;
  }
}

@media (max-width: 600px) {
  .environment-spotlight-topic-home {
    font-size: 100px;
    margin-left: 0px;
  }
}
/* section-2 end */
