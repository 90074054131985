@font-face {
  font-family: "GT-America-2";
  src: url("../../../fonts/GT-America-Compressed-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT America Trial Cm Bd";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cm Bd"),
    url("../../../fonts/GT-America-Compressed-Bold-Trial.woff") format("woff");
}

@font-face {
  font-family: "GT America Trial Cn Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Cn Lt"),
    url("../../../fonts/GT-America-Condensed-Light-Trial.woff") format("woff");
}

@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap");

/* section-1 */
.career-topic-home {
  font-size: 120px;
  color: #ffffff;
  margin-left: 80px;
  font-family: "GT-America-2", sans-serif;
}

.career-text-box {
  height: 100px;
  text-align: start;
  z-index: 1;
  position: absolute;
  top: 40vh;
}

.career-para-two {
  margin-left: -28.5%;
  font-size: 250px;
  color: #d2e1dd;
  font-family: "GT America Trial Cm Bl";
  z-index: 1;
}

@media (max-width: 768px) {
  .career-text-box {
    width: 90%;
    margin-left: 0px;
    top: 45vh;
  }

  .career-topic-home {
    font-size: 100px;
    line-height: 100px;
    margin-left: 20px;
  }
}

@media (max-width: 600px) {
  .career-text-box {
    width: 90%;
    margin-left: 0px;
    top: 60vh;
  }

  .career-topic-home {
    font-size: 80px;
    line-height: 80px;
    margin-left: 0px;
  }
}

@media (max-width: 440px) {

  .career-topic-home {
    font-size: 70px;
    line-height: 70px;
  }
}

@media (max-width: 380px) {

  .career-topic-home {
    font-size: 60px;
    line-height: 60px;
  }
}

@media (max-width: 330px) {

  .career-topic-home {
    font-size: 50px;
    line-height: 50px;
  }
}

@media (max-width: 285px) {

  .career-topic-home {
    font-size: 40px;
    line-height: 40px;
  }
}
/* section-2 */
.career-cards {
  position: relative;
  background-color: #f5f5f5a0;
}

.career-para-box {
  margin-left: 28%;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 20px;
  background-color: #ffffff;
}

.careerpage-para {
  padding-top: 100px;
  padding-bottom: 100px;
  width: 700px;
  text-align: start;
}

@media (max-width: 800px) {
  .careerpage-para {
    width: 65%;
  }
}

.career-card-width {
  overflow: hidden;
  position: relative;
  margin-top: -120px;
  width: 100%;
  z-index: 2;
  margin-left: -250px;
}

.career-para-two {
  margin-left: -28.5%;
  font-size: 250px;
  color: #d2e1dd;
  font-family: "GT America Trial Cm Bl";
  z-index: 1;
}

@media (max-width: 768px) {
  .career-card-width {
    margin-top: -70px;
    margin-left: -200px;
  }

  .career-para-two {
    font-size: 150px;
  }
}

@media (max-width: 600px) {
  .career-card-width {
    margin-top: -50px;
    margin-left: -120px;
  }

  .career-para-two {
    font-size: 70px;
    color: #d0f7ea00;
  }
}
/* section-2 end */

/* section-3 */
.career-paragraph-section-1 {
  background-color: #1e6d54;
  color: #ffffff;
  width: 100%;
  padding: 80px 150px;
}

.career-paragraph-section-1-center {
  text-align: left;
  width: 80%;
  margin: auto;
  margin-bottom: 50px;
}

.career-paragraph-section-1-center p {
  margin-bottom: 20px;
  /* font-family: "Source Code Pro", monospace; */
  font-family: "GT America Trial Cn Lt";
}

.career-paragraph-section-1-center-yello-p {
  color: #fff;
  padding-left: 50px;
  margin-top: -15px;
  list-style: disc;
  /* font-family: "Arial Narrow", Arial, sans-serif; */
  font-family: "GT America Trial Cn Lt";
  font-size: 20px;
}

@media (max-width: 1260px) {
  .career-paragraph-section-1-center p {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .career-paragraph-section-1{
    padding: 80px 100px;
  }

  .career-paragraph-section-1-center {
    width: 80%;
  }
}
/* section-3 end */
