@font-face {
  font-family: "GT-America";
  src: url("../../../../../fonts/GT-America-Compressed-Black-Trial.woff")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "GT-America-st-tr-bold";
  src: url("../../../../../fonts/GT-America-Standard-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

.childrin-education-chart-1-paragraph-two-column-2 {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  margin-top: 20px;
  font-family: "GT-America-st-tr-bold";
  width: 100%;
}

@media screen and (max-width: 900px) {
  .childrin-education-chart-1-paragraph-two-column-2 {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .childrin-education-chart-1-paragraph-column-2 {
    width: 100%;
  }
}

@media screen and (max-width: 1050px) {
  .pc {
    display: none;
  }

  .mobile {
    display: block;
  }
}

@media screen and (min-width: 1051px) {
  .mobile {
    display: none;
  }

  .pc {
    display: block;
  }
}

.childrin-education-chart-1-highlights-main-topic {
  font-family: "GT-America", sans-serif;
  color: #313893;
  font-size: 60px;
  margin-bottom: 10px;
}

.childrin-education-chart-1-img-gap-s {
  margin-bottom: 50px;
}

/* /// */
.chart-1-country-list-2 {
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  text-align: center;
}

.chart-t {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-decoration: none;
  position: relative;
  z-index: 1;
  justify-content: center;
}

.chart-t h3 {
  margin-top: 5px;
  font-size: 18px;
  text-decoration: none;
  font-weight: 700;
}

/* Basic Colours of Headings */
.chart-t.wellbeing-chart-t-01 h3 {
  color: #313893;
}
.chart-t.wellbeing-chart-t-02 h3 {
  color: #313893;
}
.chart-t.wellbeing-chart-t-03 h3 {
  color: #313893;
}
.chart-t.wellbeing-chart-t-04 h3 {
  color: #313893;
}
.chart-t.wellbeing-chart-t-05 h3 {
  color: #313893;
}
.chart-t.wellbeing-chart-t-06 h3 {
  color: #313893;
}

/* Hover Effect */
.chart-t.wellbeing-chart-t-01 h3:hover {
  color: #000055;
}
.chart-t.wellbeing-chart-t-02 h3:hover {
  color: #000055;
}
.chart-t.wellbeing-chart-t-03 h3:hover {
  color: #000055;
}
.chart-t.wellbeing-chart-t-04 h3:hover {
  color: #000055;
}
.chart-t.wellbeing-chart-t-05 h3:hover {
  color: #000055;
}
.chart-t.wellbeing-chart-t-06 h3:hover {
  color: #000055;
}

/* Selected Headings */
.chart-t.wellbeing-chart-t-01 h3.selected {
  color: #000055;
}
.chart-t.wellbeing-chart-t-02 h3.selected {
  color: #000055;
}
.chart-t.wellbeing-chart-t-03 h3.selected {
  color: #000055;
}
.chart-t.wellbeing-chart-t-04 h3.selected {
  color: #000055;
}
.chart-t.wellbeing-chart-t-05 h3.selected {
  color: #000055;
}
.chart-t.wellbeing-chart-t-06 h3.selected {
  color: #000055;
}

.main-topic-chart-1-p {
  text-align: center;
  font-size: 16px;
  font-weight: 900;
  color: rgb(49, 56, 147);
  font-family: "GT America Trial Md";
}

.topic-chart-1-p {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: rgb(49, 56, 147);
  font-family: "GT America Trial Md";
  margin-top: 20px;
}

@media (max-width:960px) {
  .chart-1-country-list-2.support-wellbeing-chart-01{
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .childrin-education-chart-1-paragraph-two-column-2 {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }
}

@media (max-width: 760px) {
  .e-pc {
    display: none;
  }
}

@media (min-width: 761px) {
  .e-mobile {
    display: none;
  }
}

@media (max-width: 760px) {
  .chart-1-country-list {
    flex-wrap: wrap;
  }
}
