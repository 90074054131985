@font-face {
    font-family: 'GT America Trial Cm Bl';
    font-style: normal;
    font-weight: normal;
    src: local('GT America Trial Cm Bl'), url('../../fonts/GT-America-Compressed-Black-Trial.woff') format('woff');
}

@font-face {
    font-family: 'GT America Trial Cn Lt';
    font-style: normal;
    font-weight: normal;
    src: local('GT America Trial Cn Lt'), url('../../fonts/GT-America-Condensed-Light-Trial.woff') format('woff');
}

@font-face {
    font-family: 'GT America Trial Lt';
    font-style: normal;
    font-weight: normal;
    src: local('GT America Trial Lt'), url('../../fonts/GT-America-Standard-Light-Trial.woff') format('woff');
}
    

.new-feature-stries-container{
    background-color: #F2F6F7;
    padding: 80px 70px 10px;
}

.feature-story-underline{
    width: 100%;
    height: 2px;
    background-color: #212529;
}

.new-feature-stry-header{
    margin-bottom: 80px;
}

.feature-story-contents-container{
    width: 80%;
}

.feature-story-contents{
    border: 2px solid #313893;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: start;
    margin-bottom: 150px;
    margin-top: 30px;
    border-radius: 10px;
    /* overflow: hidden; */
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.feature-story-contents .lowercase {
    text-transform: none;
  }

.feature-story-content-left{
    width: 45%;
}

.feature-story-content-right{
    width: 45%;
}

.feature-story-content-right img{
    width: 95%;
    object-fit: cover;
}

.feature-story-contents:nth-of-type(2){
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 20px;
}

.feature-story-topic h1{
    font-family: 'GT America Trial Cm Bl';
    font-size: 66px;
}

.feature-story-content-left h3{
    color: #15D699;
    font-family: 'GT America Trial Cn Lt';
    text-transform: uppercase;
    font-size: 30px;
}

.feature-story-content-left h1{
    color: #313893;
    font-family: 'GT America Trial Cm Bl';
    font-size: 66px;
    text-transform: uppercase;
}

.feature-story-content-left p{
    color: #212529;
    font-family: 'GT America Trial Lt';
}

.feature-story-contents:nth-of-type(3){
    margin-bottom: 100px;
}


/*------------------------------------ Media Query --------------------------------*/
@media (max-width:1000px) {
    .new-feature-stries-container{
        padding: 80px 70px 10px;
    }

    .feature-story-contents-container{
        width: 90%;
    }

    .feature-story-content-left{
        width: 40%;
    }
    
    .feature-story-content-right{
        width: 45%;
    }

    .feature-story-contents:nth-of-type(3) img{
        height: 70%;
    }

    .feature-story-contents:nth-of-type(2) img{
        height: 65%;
    }

    .feature-story-contents:nth-of-type(1) img{
        height: 70%;
    }
}

@media (max-width:750px) {

    .feature-story-contents{
        flex-direction: column;
        margin-bottom: 80px;
    }

    .feature-story-contents:nth-of-type(2){
        flex-direction: column;
        gap: 0;
    }

    .feature-story-contents-container{
        width: 80%;
    }

    .feature-story-content-left{
        width: 100%;
        margin-bottom: 20px;
    }

    .feature-story-content-right{
        width: 100%;
    }
    
    .feature-story-contents img{
        width: 70%;
    }
    
}

@media (max-width:600px) {
    
    .feature-story-topic h1{
        font-size: 50px;
    }

    .feature-story-content-left h3{
        font-size: 25px;
    }

    .feature-story-content-left h1{
        font-size: 50px;
    }

    .feature-story-contents img{
        width: 90%;
    }
    
}

@media (max-width:500px) {
    
    .feature-story-contents-container{
        width: 90%;
    }

    .feature-story-topic h1{
        font-size: 40px;
    }

    .feature-story-content-left h3{
        font-size: 20px;
    }

    .feature-story-content-left h1{
        font-size: 40px;
    }

    .feature-story-contents img{
        width: 100%;
    }
    
    .new-feature-stry-header{
        margin-bottom: 50px;
    }
}

@media (max-width:400px) {
    
    .new-feature-stries-container{
        padding: 80px 30px 10px;
    }

    .feature-story-contents-container{
        width: 90%;
    }

    .feature-story-topic h1{
        font-size: 40px;
    }

    .feature-story-content-left h3{
        font-size: 20px;
    }

    .feature-story-content-left h1{
        font-size: 40px;
    }

    .feature-story-contents img{
        width: 100%;
    }
    
    .new-feature-stry-header{
        margin-bottom: 50px;
    }
}

@media (max-width:400px) {
    
    .new-feature-stries-container{
        padding: 80px 30px 10px;
    }

    .feature-story-topic h1{
        font-size: 35px;
    }

    .feature-story-content-left h3{
        font-size: 20px;
    }

    .feature-story-content-left h1{
        font-size: 35px;
    }
}

@media (max-width:305px) {
    
    .new-feature-stries-container{
        padding: 80px 20px 10px;
    }

    .feature-story-topic h1{
        font-size: 30px;
    }

    .feature-story-content-left h3{
        font-size: 18px;
    }

    .feature-story-content-left h1{
        font-size: 30px;
    }

    .feature-story-content-left p{
        font-size: 14px;
    }
}