/* section-1 */
.empowere-topic-home {
  font-size: 120px;
  color: #ffffff;
  margin-left: 80px;
  font-family: "GT-America-2", sans-serif;
}

.home-video {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}

.home-page-video {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
/* section-1 end */

.screen-page {
  width: 100%;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .screen-page {
    overflow-y: hidden;
  }
}

/* video topic */
.empowere-video-text-box {
  height: 100px;
  text-align: start;
  z-index: 1;
  position: absolute;
  top: 40vh;
}

@media (max-width: 768px) {
  .empowere-video-text-box {
    width: 90%;
    margin-left: 0px;
    top: 45vh;
  }

  .empowere-topic-home {
    font-size: 150px;
    margin-left: 20px;
  }
}

@media (max-width: 600px) {
  .empowere-video-text-box {
    width: 90%;
    margin-left: 0px;
    top: 75vh;
  }

  .empowere-topic-home {
    font-size: 100px;
    margin-left: 0px;
  }
}

/* section-2 */
.empowerpage-para {
  padding: 100px 60px;
  width: 100%;
  text-align: start;
}

.empowerpage-para h1 {
  font-family: "GT America Trial Cm Bd";
}

.empowerpage-para p {
  /* font-family: "Arial Narrow", Arial, sans-serif; */
  font-family: "GT America Trial Cn Lt";
  font-size: 20px;
}

.empowerpage-para-conatiner {
  display: flex;
  align-items: center;
}

/* section-3 */
.empower-card {
  position: relative;
  background-color: #f5f5f584;
}

.para-box {
  margin-left: 28%;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 20px;
  background-color: #ffffff;
}

.para-one-1 {
  font-size: 45px;
  font-family: "GT America Trial Cm Bl";
  font-weight: 700;
  line-height: 66.56px;
  text-transform: uppercase;
  color: #313893;
}

.para-one-2 {
  margin-top: -30px;
  font-size: 45px;
  font-family: "GT America Trial Cm Bl";
  font-weight: 700;
  line-height: 66.56px;
  text-transform: uppercase;
  color: #313893;
}

.empower-para-two {
  margin-left: -28.5%;
  font-size: 250px;
  color: #d9f0fa;
  font-family: "GT America Trial Cm Bl";
}

.empower-flip-part {
  margin-top: 0px;
  margin-left: -20%;
}

@media (max-width: 768px) {
  .para-one-1 {
    font-size: 25px;
  }

  .para-one-2 {
    font-size: 25px;
  }

  .empower-para-two {
    font-size: 150px;
  }
}

@media (max-width: 600px) {
  .para-one-1 {
    font-size: 25px;
    margin-left: -55px;
  }

  .para-one-2 {
    font-size: 25px;
    margin-left: -55px;
  }

  .empower-para-two {
    font-size: 70px;

    color: #d0f7ea00;
  }

  .empower-flip-part {
    margin-left: -45%;
  }
}

/* 
.bac-card {
  width: 25%;
  height: 100vh;
  background-color: #f5f5f584;
  position: relative;
}

.para-one-1 {
  position: absolute;
  top: 10%;
  padding-left: 28%;
  width: 90%;
  margin: 0;
  font-size: 45px;
  font-family: "GT America Trial Cm Bl";
  font-weight: 700;
  line-height: 66.56px;
  text-transform: uppercase;
  color: #313893;
}

.para-one-2 {
  position: absolute;
  top: 17%;
  padding-left: 28%;
  width: 90%;
  margin: 0;
  font-size: 45px;
  font-family: "GT America Trial Cm Bl";
  font-weight: 700;
  line-height: 66.56px;
  text-transform: uppercase;
  color: #313893;
}

.para-two {
  position: absolute;
  top: 20%;
  padding-left: 8%;
  width: 85%;
  margin: 0;
  font-size: 250px;
  color: #d0f7ea;
  font-family: "GT America Trial Cm Bl";
}

.flip-part {
  position: absolute;
  padding-left: 20%;
  top: 50%;
} 
*/
