@font-face {
  font-family: "GT-America-st-tr-bold";
  src: url("../../../../../fonts/GT-America-Standard-Bold-Trial.woff")
    format("truetype");
  font-style: normal;
}

@media (max-width: 870px) {
  .aa-pc {
    display: none;
  }
}

@media (min-width: 870px) {
  .aa-mobile {
    display: none;
  }
}

.conserve-energy-chart-04-paragraph-two-column {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.conserve-energy-chart-04-paragraph-column-2 {
  width: 75%;
  margin-top: auto;
  margin-bottom: auto;
  font-family: "GT-America-st-tr-bold";
}

.conserve-energy-chart-04-paragraph-column-3{
  text-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  font-family: "GT-America-st-tr-bold";
}

@media screen and (max-width: 870px) {
  .conserve-energy-chart-04-paragraph-two-column {
    flex-direction: column;
    height: auto;
    width: 100%;
  }

  .conserve-energy-chart-04-paragraph-column-2{
    width: 100%;
    padding: 20px;
    text-align: center;
    border-right: none;
    border-left: 1.4px solid #313893;
    border-bottom: 1.4px solid #313893;
  }

  .conserve-energy-chart-04-paragraph-column-3{
    width: 100%;
    padding: 20px;
    text-align: center;
    border-left: 1.4px solid #313893;
    border-bottom: none;
  }
}

.conserve-energy-chart-04-text-box-1-opacity {
  background-color: transparent;
  width: 100%;
  /* color: #091294; */
  color: #000055;
  font-size: 18px;
  font-weight: 700;
  opacity: 0.4;
  cursor: pointer;
}

.conserve-energy-chart-04-text-box-2-opacity {
  opacity: 0.4;
  background-color: transparent;
  width: 100%;
  /* color: #e96b12ee; */
  color: #000055;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.conserve-energy-chart-04-text-box-3-opacity {
  opacity: 0.4;
  background-color: transparent;
  width: 100%;
  /* color:#ee0505; */
  color: #000055;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.conserve-energy-chart-04-text-box-4-opacity {
  opacity: 0.4;
  background-color: transparent;
  width: 100%;
  /* color: #1c7401; */
  color: #000055;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.conserve-energy-chart-04-text-box-5-opacity {
  opacity: 0.4;
  background-color: transparent;
  width: 100%;
  /* color: #003775; */
  color: #000055;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}
.selected-text{
  opacity: 1;
}

.conserve-chart-heading{
  color: #313893;
  font-size: 18px;
  font-weight: 900;
  font-family: "GT America Trial Md";
  margin-bottom: 70px;
}

.conserve-energy-chart-04-card-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.conserve-energy-chart-04-card {
  flex: 1 1 auto;
  min-width: 110px;
  margin-bottom: 20px;
  overflow: hidden;
  margin: 2.5px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  justify-items: center;
  text-align: center;
}

.conserve-energy-chart-04-number {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3%;
  margin-bottom: 15px;
}

.conserve-energy-chart-04-number.number-content-01{
  /* color: #67007c; */
  color: #000055;
}

.conserve-energy-chart-04-number.number-content-02{
  /* color: rgb(0, 71, 49); */
  color: #000055;
}

.conserve-energy-chart-04-number.number-content-03{
  /* color: #00438f; */
  color: #000055;
}

.conserve-energy-chart-04-letter{
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3%;
  margin-top: 15px;
}

.conserve-energy-chart-04-letter.letter-content-01 h4{
  /* color: #67007c; */
  color: #000055;
  font-size: 16px;
}

.conserve-energy-chart-04-letter.letter-content-02 h4{
  /* color: rgb(0, 71, 49); */
  color: #000055;
  font-size: 16px;
}

.conserve-energy-chart-04-letter.letter-content-03 h4{
  /* color: #00438f; */
  color: #000055;
  font-size: 16px;
}
