@font-face {
  font-family: "GT America Trial Lt";
  font-style: normal;
  font-weight: normal;
  src: local("GT America Trial Lt"),
    url("../../../fonts/GT-America-Standard-Light-Trial.woff") format("woff");
}

.waste-card {
  margin-left: 10px;
  margin-right: 10px;
  padding: 20px;
  font-family: "GT America Trial Lt";
  border-radius: 20px;
  text-align: start;
  width: 90%;
  height: 280px;
  position: relative;
}

/* Old Slider */
/* .waste-card-hover-description{
  display: none;
  width: 100%;
  position: absolute;
  bottom: -20px;
  border-radius: 20px;
  left: 0;
  padding: 30px 30px;
  color: #fff;
  animation: slideup 0.5s;
  transition: 0.5s ease-in-out;
}

@keyframes slideup {
  from{
    bottom: -100px;
  }
  to{
    bottom: -20px;
  }
}

.waste-card-circle-container:hover .waste-card-hover-description{
  display: block; 
} */


/* New POPUP */

.waste-card-circle-container{
  position: relative;
}

.hover-area{
  visibility: hidden;
  width: 80%;
  text-align: center;
  padding: 10px 5px;
  border-radius: 6px;
  position: absolute;
  top:-8%;
  left: 25%;
  background-color: #b3e2d6;
}

.tooltip-texts{
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
}

.waste-card-circle:hover + .hover-area{
  visibility: visible;
}

.hover-area-arrow{
  position: absolute;
  rotate: 180deg;
  left: -15px;
  top: 15%;
}



/* .waste-card-hover-description{
  display: none;
  width: 100%;
  position: absolute;
  bottom: -20px;
  border-radius: 20px;
  left: 0;
  padding: 30px 30px;
  color: #fff;
  animation: slideup 0.5s;
  transition: 0.5s ease-in-out;
} */

/* @keyframes slideup {
  from{
    bottom: -100px;
  }
  to{
    bottom: -20px;
  }
} */


.waste-card-title {
  padding: 20px;
  text-align: end;
  margin-top: -60px;
  margin-bottom: -20px;
  font-weight: bold;
}

.waste-card-description {
  padding: 20px;
}

.waste-card-circle {
  margin-top: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.waste-card-circle p {
  margin: 0;
  font-size: 13px;
}

/* Ensure the arrows are visible on smaller screens */
.waste-arrow {
  position: absolute;
  top: 50%;
  z-index: 100;
  font-size: 25px;
  line-height: 25px;
  cursor: pointer;
  color: #a3e1cc;
  transition: color 0.1s ease;
}

.waste-arrow:hover {
  color: #808080;
}

.left-arrow-waste {
  left: 0px;
}

.right-arrow-waste {
  right: 0px;
}

/* Media Query */

@media (max-width:1100px) {
  .waste-card {
    height: 300px;
  }
}

@media (max-width:940px) {
  .waste-card {
    height: 330px;
  }
}

@media (max-width:742px) {
  .waste-card {
    height: 320px;
  }
}

@media (max-width:600px) {
  .waste-card {
    height: 280px;
  }
}

@media (max-width:360px) {
  .waste-card {
    height: 320px;
  }
}

@media (max-width:300px) {
  .waste-card {
    height: auto;
  }
}
