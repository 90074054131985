.career-card-container-5 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card {
  flex: 1 1 auto;
  margin-bottom: 20px;
  overflow: hidden;
  margin: 2.5px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  justify-items: center;
  text-align: center;
}

.card.empower-equality-chart-01{
  margin-bottom: 20px;
}

.career-chart-img-2 {
  max-width: 100%;
}

.Sri_Lanka_2023 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 3%;
  /* color: #00438f; */
  color: #000055;
}

.Sri_Lanka_2022 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 3%;
  /* color: #e05e00; */
  color: #000055;
}

.Sri_Lanka_Target {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 3%;
  /* color: #b80101; */
  color: #000055;
}

.equality-chart-2-sri_lanka-number-loader {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #ffffff;
}

.career-para-5-female {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3%;
  color: #ec7372;
}

.middle-topic {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
  font-weight: 700;
  color: #ffffff;
}

.chart-box.empower-equality-chart-01{
  width: 100%;
}

@media screen and (max-width: 900px) {
 .chart-box {
  width: auto;
}
}