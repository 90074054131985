.icon-container-empower {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.icon-column {
  padding: 0px;
  position: relative;
}

.ban-gap {
  width: 100%;
}

.Children-flip-button-image-1 {
  border-radius: 10px;
  height: 350px;
  width: 250px;
  background-color: #313893;
  background-size: cover;
}

.Children-flip-button-image-1:hover {
  background-color: #31379343;
}

.Children-flip-button-image-2 {
  border-radius: 10px;
  height: 350px;
  width: 250px;
  background-image: url("../../../assets/wellbing-card.webp");
  background-size: cover;
}

.Children-flip-button-image-2:hover {
  background-image: none;
  background-color: #19322a;
}

.Children-flip-button-image-3 {
  border-radius: 10px;
  height: 350px;
  width: 250px;
  background-image: url("../../../assets/happy-people-11934050.webp");
  background-size: cover;
}

.Children-flip-button-image-3:hover {
  background-image: none;
  background-color: #3c90bb;
}
.topic-overlay{
  line-height: 36px;
}

.Children-topic-overlay{
  top: 78%;
}

/* .Children-flip-button-image-4 {
  border-radius: 10px;
  height: 350px;
  width: 250px;
  background-image: url("../../../assets/new_conserve_waste.png");
  background-size: cover;
}

.equality-flip-button-image-4:hover {
  background-image: none;
  background-color: #03230e;
} */


/* Media Query */
@media (max-width: 768px) {
  .ban-gap {
    width: 80%;
  }

  .icon-container-empower {
    grid-column: span 1;
    justify-self: center;
  }
}